import React, {Component} from 'react';

import {
    SimpleForm,
    FileInput,
    FileField,
    // SaveButton,
    Toolbar,
    useRefresh
} from 'react-admin';

import Button  from '@material-ui/core/Button';
import {convertMessageCodeToMessage, request} from "../../util";
import {translate} from "../../i18n/customI18nProvider";
import Grid from '@material-ui/core/Grid';

const PostCreateToolbar = props => (
    <Toolbar {...props} style={{visibility: 'hidden', display: 'none'}} >
        {/* <SaveButton
            style={{visibility: 'hidden', display: 'none'}}
        /> */}
    </Toolbar>
);

class ClientImportClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null
        };

        this.importClients = this.importClients.bind(this);
    }

    async importClients() {

        if (!this.state.selectedFile) {
            alert(translate('custom.please_select_file'));
            return;
        }

        // this.props.isImporting(true);

        let response = await request('/clients/import', 'POST', null, {'clients_data' : this.state.selectedFile});

        if(response.error) {
            let error = response.error;
            if (response.error_code) {
                error = convertMessageCodeToMessage(response.error_code);
            }
            alert(error);
            // this.props.isImporting(false);
            return
        }

        let response_string = `${translate('custom.import_completed_with')} \n${translate('custom.success')} - ${response.success} \n${translate('custom.failure')} - ${response.failure} \n${translate('custom.skipped')} - ${response.skipped}`
        alert(response_string);

        this.props.refresh();

    };

    async handleChange(e) {
        if (e) {
            this.setState({selectedFile: await e.text()})
        } else {
            this.setState({selectedFile: null})
        }
    }

    render() {
        return (
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center', border: '1px solid lightgray', margin: '10px', borderRadius: '10px'}}>
                    <SimpleForm toolbar={<PostCreateToolbar />} >
                        <FileInput
                            source="files"
                            // label="Import Clients"
                            label={translate('custom.import_clients_by_providing_entrance_or_ticket_id_in_csv_format')}
                            // accept=".csv"
                            multiple={false}
                            onChange={(e) => {
                                this.handleChange(e)
                                // this.setState({'setSelectedFile' : e.target.files[0]})
                            }}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>

                        <Button
                            color="primary"
                            onClick={this.importClients}
                            variant="contained"
                            size="small"
                        >
                            {translate('custom.import')}
                        </Button>

                    </SimpleForm>
                </Grid>
            </Grid>
        );
    }
}

const ClientImport = (props) => {
    const refresh = useRefresh();
    return <ClientImportClass {...props} refresh={refresh} />
}
export default ClientImport;