import axios from "axios";
import config from "./config";
import { translate } from './i18n/customI18nProvider';
import { getLocale, setLocale, getToken, clear } from './services/storage';

export async function request(url, method, token, request, useToken = true) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (useToken) {
        if (token)
            headers['x-access-token'] = token;
        else
            headers['x-access-token'] = getToken();
    }

    let options = {
        method,
        headers,
    };

    if (request) {
        options.body = JSON.stringify(request);
    }

    let responseJson;
    responseJson = await fetch(config.apiUrl + url, options);
    let response = await responseJson.json();

    if (response && response.error) {
        if(response.code === 102){
            clear();
            window.location.reload();
        }
        console.log(response.error);
    }

    return response;
}

// request with multipart form data
export async function requestMultipart(url, method, token, formDataRequestBody, useToken = true) {
    let headers = {
        'Content-Type': 'multipart/form-data'
    };

    if (useToken) {
        if (token)
            headers['x-access-token'] = token;
        else
            headers['x-access-token'] = getToken();
    }

    let options = {
        method,
        headers,
    };

    if (request) {
        options.body = request;
    }

    // let responseJson;
    // responseJson = await fetch(config.apiUrl + url, options);

    let response = await axios.post(config.apiUrl + url, formDataRequestBody,
        {
            mode: 'cors',
            headers: headers
        }
    )

    // let response = await responseJson.json();

    // if (response && response.error) {
    //     if(response.code === 102){
    //         clear();
    //         window.location.reload();
    //     }
    //     console.log(response.error);
    // }

    return response;
}

export function formatCurrencyWithCode(x, currencyCode=null) {
    let style = {};
    if (currencyCode) {
        style = { style: 'currency', currency: currencyCode };
    }
    return new Intl.NumberFormat('en', style).format(x);
}

export function formatCurrency(x){
    return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(x).replace(/,/g, "'").replace('$', "");
}

export function formatCurrencySign(x){
    let sign = x < 0 ? '-' : (x=== 0 ? '' : '+');
    x = Math.abs(x);
    return sign + new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(x).replace(/,/g, "'").replace('$', "");
}

export function formatNumber(x){
    return new Intl.NumberFormat('en').format(x).replace(/,/g, "'");
}

export function gFormatCurrency(x){
    return {v: x, f: formatCurrency(x)};
}

export function gFormatNumber(x){
    return {v: x, f: formatNumber(x)};
}

export function objectIdFromDate(date) {
    return Math.floor(date.getTime() / 1000).toString(16) + "0000000000000000";
}

export function dateFromObjectId(objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
    // return String(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export function getLabel(index, label){
    for(let i in label)
        if(index === label[i].value)
            return label[i].name;
    return "Non défini";
}

export function getChoices(values, type=null, sub_unit='', unit=''){
    return Object.entries(values).map((a) => {

        if (type === 'currency') {
            if (a[1].value < 1) {
                return {id: a[1].value, name: (parseInt(parseFloat(a[1].name) * 100)) + ' ' + sub_unit}
            } else {
                return {id: a[1].value, name: a[1].name + ' ' + unit}
            }
        } else {
            return {id: a[1].value, name: a[1].name}
        }
    });
}

export async function languageCheckAndReload(lang=null) {
    if(!lang) {
        let parameterLanguage = await request('/events/parameter/language', 'GET');
        lang = parameterLanguage.language;
    }
    if (lang && (lang != getLocale())) {
        setLocale(lang)
        window.location.reload();
    }
}

export function fillMessageVariable(message, payload={}) {
    // message = I would like to donation |currency_code| |amount| which will be used to cover the transaction charges.
    // payload = {currency_code: 'USD', amount: '100.00'}
    // return I would like to donation USD 100.00 which will be used to cover the transaction charges.
    let codes = message.match(/(?:\|)[^\|\|]*?(?:\|)/g) || [];
    let newMessage = message;
    for(let i=0; i<codes.length; i++){
        let code = codes[i].replace(/\|/g, '');
        newMessage = newMessage.replace(codes[i], payload[code]);
    }
    return newMessage;
}

export function convertMessageCodeToMessage(messageCode){
    let codes = messageCode.match(/(?:\|)[^\|\|]*?(?:\|)/g) || [];
    let message = messageCode;
    for(let i=0; i<codes.length; i++){
        message = message.replace(codes[i], translate('custom.'+codes[i].replace(/\|/g, '')));
    }
    return message;
}

export function getRequestOptions(method) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    headers['x-access-token'] = getToken();

    let options = {
        method: method,
        headers
    };

    return options;
}

export async function downloadFileFromResponse(response, file_name, axios=false) {
    let blob;
    if(axios) {
        blob = await response.data;
    } else {
        blob = await response.blob();
    }
    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blob);
}