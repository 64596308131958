import config from "../config";

const headerTextStyles = {
    width: '100%',
    borderBottom: '2px solid' + config.baseColor,
    color: config.baseColor
}

export {
    headerTextStyles
}