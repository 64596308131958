import { Button } from '@mui/material';
import React from 'react';
import { translate } from '../../../i18n/customI18nProvider';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VisuallyHiddenInput from '../common/input/VisuallyHiddenInput';
import { requestMultipart } from '../../../util';
import { importDevices } from '../../../services/device.service';
import Swal from 'sweetalert2';

class ImportDevices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            loading: false
        };
    }

    importDevices = async () => {
            
        if (!this.state.selectedFile) {
            this.setState({loading: false});
            alert(translate('custom.please_select_file'));
            return;
        }

        let requestBody = new FormData();
        requestBody.append('file', this.state.selectedFile);
        let response = await importDevices(requestBody);
        
        if(response.error) {
            let error_message = response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error;
            alert(error_message);
            this.setState({loading: false});
            return;
        }

        Swal.fire({
            title: translate('custom.success'),
            text: translate('custom.devices_imported_successfully'),
            icon: 'success',
            confirmButtonText: translate('custom.ok')
        });

        this.setState({loading: false});
    };


    render() {
        return (
            <Button component='label' variant='contained' style={{textTransform: 'none'}}
                startIcon={<FileUploadIcon />}
            >
                {this.state.loading ? translate('custom.importing') : translate('custom.import_devices')}
                <VisuallyHiddenInput
                    type='file'
                    accept='.xlsx'
                    onChange={async (e) => {
                        await this.setState({loading: true});
                        await this.setState({selectedFile: e.target.files[0]});
                        await this.importDevices();
                        await this.setState({loading: false});
                    }}
                />
            </Button>
        )
    }
}

export default ImportDevices;
