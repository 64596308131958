import React, { Component } from 'react';
import '../css/App.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {formatCurrency} from "../util";

import { translate } from "../i18n/customI18nProvider";

class VouchersTable extends Component {

    max_allowed_height = 750;
    page_height = 1600;

    componentDidMount() {
        const element = document.getElementById('voucher_table');
        const element_print = document.getElementById('voucher_table_print');
        if (element) {
            // console.log(element.offsetHeight);
            if (element.offsetHeight > this.max_allowed_height) {
                let times = Math.ceil(element.offsetHeight / this.page_height);
                element_print.style.marginTop = this.max_allowed_height + 100 + "px";
                element_print.style.height = (times * this.page_height) - 150 - ((times-1) * 100 ) + "px";
            } else if (element.offsetHeight <= this.max_allowed_height) {
                element_print.style.marginBottom = this.max_allowed_height - element.offsetHeight + 50 + "px";
            }
        }
    }

    render(){

        const vouchers = this.props.vouchers ? this.props.vouchers : [];
        const new_vouchers = [];

        const vouchers_total = {
            available: 0,
            used: 0,
            available_value: 0,
            used_value: 0
        }

        vouchers.forEach((voc) => {
            if(voc.available || voc.used) {

                if("available" in voc) {
                    vouchers_total["available"] += voc["available"];
                }
                
                if("available_value" in voc) {
                    vouchers_total["available_value"] += parseFloat(voc["available_value"]);
                    voc["available_value"] = formatCurrency(voc["available_value"]);
                }
                    
                vouchers_total["used"] += voc["used"];
                vouchers_total["used_value"] += parseFloat(voc["used_value"]);

                voc["used_value"] = formatCurrency(voc["used_value"]);
                voc["highest_value"] = formatCurrency(voc["highest_value"]);
                new_vouchers.push(voc);
            }
        });

        if(!new_vouchers.length) {
            return (
                <>
                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: this.max_allowed_height + 45}}></div>
                    </div>
                </>
            );
        }

        vouchers_total["available_value"] = formatCurrency(vouchers_total["available_value"]);
        vouchers_total["used_value"] = formatCurrency(vouchers_total["used_value"]);

        let view = (
            <>
                <h1 className={this.props.classes.titleTextStyle}>{translate('custom.vouchers')}</h1>
                <Box overflow="auto">
                    <Table style={{marginBottom: 20}} >
                        <TableBody className={this.props.classes.table_head} >
                            <TableRow>
                                <TableCell className={this.props.classes.table_head}>{translate('custom.voucher')}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{('available' in new_vouchers[0]) ? translate('custom.max_value') : ''}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{('available' in new_vouchers[0]) ? translate('custom.available') : ''}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{translate('custom.used')}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{('available_value' in new_vouchers[0]) ? translate('custom.available_value') : ''}</TableCell>
                                <TableCell className={this.props.classes.table_head}>{translate('custom.used_value')}</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            {new_vouchers.map((voucher) => (
                                <TableRow key={voucher._id} >
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{('name' in voucher) ? voucher.name : ''}</TableCell>
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{('available' in voucher) ? voucher.highest_value : ''}</TableCell>
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{('available' in voucher) ? voucher.available : ''}</TableCell>
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{('used' in voucher) ? voucher.used : ''}</TableCell>
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{('available_value' in voucher) ? voucher.available_value : ''}</TableCell>
                                    <TableCell className={this.props.classes.table_amount} numeric="true">{('used_value' in voucher) ? voucher.used_value : ''}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableBody className={this.props.classes.table_head} >
                            <TableRow>
                                <TableCell className={this.props.classes.table_head}>{translate('custom.total')}</TableCell>
                                <TableCell className={this.props.classes.table_head}></TableCell>
                                <TableCell className={this.props.classes.table_head_amount} numeric="true">{('available' in new_vouchers[0]) ? vouchers_total.available : ''}</TableCell>
                                <TableCell className={this.props.classes.table_head_amount} numeric="true">{('used' in vouchers_total) ? vouchers_total.used : ''}</TableCell>
                                <TableCell className={this.props.classes.table_head_amount} numeric="true">{('available_value' in new_vouchers[0]) ? vouchers_total.available_value : ''}</TableCell>
                                <TableCell className={this.props.classes.table_head_amount} numeric="true">{('used_value' in vouchers_total) ? vouchers_total.used_value : ''}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </>
        );

        return (
            <div>
                <div className="noPrint">
                    <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto"}} id="voucher_table">
                        {view}
                    </div>
                </div>
                <div className="print">
                    <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto"}} id="voucher_table_print">
                        {view}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    table_head: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: 'right',
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_head_amount: {
        textAlign: 'right',
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_amount: {
        textAlign: 'right',
        fontSize: 15
    },
    table_row: {
        fontSize: 15
    },
    titleTextStyle: {
        color: theme.palette.primary.main,
        fontSize: 24,
        bold: true,
        marginBottom: 15
    }
});

VouchersTable.defaultProps= {
    statistics: {}
};

export default withStyles(styles)(VouchersTable);

