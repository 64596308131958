import React, {Component} from 'react';
import { Chart } from 'react-google-charts';
import { convertMessageCodeToMessage } from "../util";
import theme from "./Theme";
import {translate} from "../i18n/customI18nProvider";

const graph_colors_history = [
    theme.palette.primary.light,
    theme.palette.primary.dark
];

const titleTextStyle = {
    color: theme.palette.primary.main,
    fontSize: 24,
    bold: true,
};

class PeopleInEventLineChart extends Component {
    render() {

        const { statistics } = this.props;

        if(statistics && statistics.history_people_in_event && statistics.history_people_in_event.length > 0 && statistics.history_people_in_event[0].length > 0) {

            statistics.history_people_in_event[0][1] = convertMessageCodeToMessage(statistics.history_people_in_event[0][1]);

            let found = false;
            for(let i=1; i<statistics.history_people_in_event.length; i++) {
                if( parseFloat(statistics.history_people_in_event[i][1]['f']) !== 0 ) {
                    found = true;
                    break;
                }
            }

            if(!found) {
                return (
                    <>
                        <div className="print">
                            <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 500}}></div>
                        </div>
                    </>
                );
            }

            let view = (
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="500px"
                    data={statistics ? statistics.history_people_in_event : []}
                    options={{
                        title: translate('custom.people_in_event'),
                        titleTextStyle,
                        vAxis: {
                            title: translate('custom.number'),
                            format: 'short'
                        },
                        hAxis: {
                            format: translate('custom.OPTIONS.chart_date_time_format')
                        },
                        colors: graph_colors_history,
                        backgroundColor: {fill: 'transparent'}
                    }}
                    graph_id="PeopleInEventChart"
                />
            );

            return (
                <>
                    {view}
                </>
            )

        } else {
            return (
                <>
                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 500}}></div>
                    </div>
                </>
            )
        }
    }
}

export default PeopleInEventLineChart;