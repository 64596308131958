const keys = {
    LOCALE: 'locale',
    TOKEN: 'token',
    RIGHT: 'right',
    PERIODID: 'periodId',
    NAME: 'name',
    THEME: 'theme',
    NOTIFICATION_TOKEN: '_notification_token'
}


const getItem = (key, mode='auto') => {
    if (mode === 'localStorage') {
        return localStorage.getItem(key);
    }

    if (mode === 'sessionStorage') {
        return sessionStorage.getItem(key);
    }

    // Auto mode, if found in sessionStorage, return it else return from localStorage
    if (sessionStorage.getItem(key)) {
        return sessionStorage.getItem(key);
    }
    return localStorage.getItem(key);
}

const setItem = (key, value, mode='auto') => {
    if (mode === 'localStorage') {
        localStorage.setItem(key, value);
        return true;
    }

    if (mode === 'sessionStorage') {
        sessionStorage.setItem(key, value);
        return true;
    }

    // Auto mode, if found in localStorage, set to sessionStorage else set to localStorage
    let localStorageValue = getItem(key, 'localStorage');
    if (localStorageValue) {

        // If localStorageValue is same as value, no need to set
        if (localStorageValue === value) {
            return true;
        }
        
        sessionStorage.setItem(key, value);
        return true;
    }
    localStorage.setItem(key, value);
    return true;
}

const removeItem = (key, mode='auto') => {
    if (mode === 'localStorage') {
        localStorage.removeItem(key);
        return true;
    }

    if (mode === 'sessionStorage') {
        sessionStorage.removeItem(key);
        return true;
    }

    // Auto mode, if found in sessionStorage, remove it else remove from localStorage
    if (getItem(key, 'sessionStorage')) {
        sessionStorage.removeItem(key);
        return true;
    }
    localStorage.removeItem(key);
    return true;
}

const clear = (mode='auto') => {
    if (mode === 'localStorage') {
        localStorage.clear();
        return true;
    }

    if (mode === 'sessionStorage') {
        sessionStorage.clear();
        return true;
    }

    // Auto mode, if found in sessionStorage, clear it else clear localStorage
    // If 'token' found in sessionStorage, clear sessionStorage else clear localStorage
    if (getItem(keys.TOKEN, 'sessionStorage')) {
        sessionStorage.clear();
        return true;
    }
    localStorage.clear();
    return true;
}


// Locale
const getLocale = () => {
    return getItem(keys.LOCALE);
}

const setLocale = (locale) => {
    setItem(keys.LOCALE, locale, 'sessionStorage');
    return setItem(keys.LOCALE, locale);
}

const removeLocale = () => {
    return removeItem(keys.LOCALE);
}

// Token
const getToken = () => {
    return getItem(keys.TOKEN);
}

const setToken = (token) => {
    return setItem(keys.TOKEN, token);
}

const removeToken = () => {
    return removeItem(keys.TOKEN);
}

// Right
const getRight = () => {
    return parseInt(getItem(keys.RIGHT));
}

const setRight = (right) => {
    return setItem(keys.RIGHT, String(right));
}

const removeRight = () => {
    return removeItem(keys.RIGHT);
}

// PeriodID
const getPeriodID = () => {
    return getItem(keys.PERIODID);
}

const setPeriodID = (periodID) => {
    return setItem(keys.PERIODID, periodID);
}

const removePeriodID = () => {
    return removeItem(keys.PERIODID);
}

// Name
const getName = () => {
    return getItem(keys.NAME, 'sessionStorage');
}

const setName = (name) => {
    return setItem(keys.NAME, name, 'sessionStorage');
}

const removeName = () => {
    return removeItem(keys.NAME);
}

// Theme
const getTheme = () => {
    return getItem(keys.THEME);
}

const setTheme = (theme) => {
    return setItem(keys.THEME, theme);
}

const removeTheme = () => {
    return removeItem(keys.THEME);
}

// Client notification token
const getClientNotificationToken = (id) => {
    return getItem(id + keys.NOTIFICATION_TOKEN);
}

const setClientNotificationToken = (id, token) => {
    return setItem(id + keys.NOTIFICATION_TOKEN, token);
}

const removeClientNotificationToken = (id) => {
    return removeItem(id + keys.NOTIFICATION_TOKEN);
}


module.exports = {
    getItem,
    setItem,
    removeItem,
    clear,
    getLocale,
    setLocale,
    removeLocale,
    getToken,
    setToken,
    removeToken,
    getRight,
    setRight,
    removeRight,
    getPeriodID,
    setPeriodID,
    removePeriodID,
    getName,
    setName,
    removeName,
    getTheme,
    setTheme,
    removeTheme,
    getClientNotificationToken,
    setClientNotificationToken,
    removeClientNotificationToken
}