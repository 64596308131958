import react from 'react';
import V2Layout from './Layout';
import { Box, Button, Card, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import StyledTableRow from '../../components/v2/common/table/StyledTableRow';
import { translate } from '../../i18n/customI18nProvider';
import moment from 'moment/moment';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import config from '../../config';
import SearchIcon from '@mui/icons-material/Search';
import { getTicketList } from '../../services/ticket.service';
import QRCode from 'react-qr-code';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const tableHeader = [
    {
        id: 'event',
        key: 'event',
        align: 'left',
        disablePadding: false,
        label: translate('custom.event'),
        sortable: true
    },
    {
        id: 'ticket_provider',
        key: 'ticket_provider',
        align: 'left',
        disablePadding: false,
        label: translate('custom.ticket_provider'),
        sortable: true
    },
    {
        id: 'ticket_number',
        key: 'ticket_number',
        align: 'left',
        disablePadding: false,
        label: translate('custom.ticket_number'),
        sortable: true
    },
    {
        id: 'ticket_qr',
        key: 'ticket_qr',
        align: 'left',
        disablePadding: false,
        label: translate('custom.ticket_qr'),
        sortable: true
    },
    {
        id: 'event_name',
        key: 'event_name',
        align: 'left',
        disablePadding: false,
        label: translate('custom.event_name'),
        sortable: true
    },
    {
        id: 'zone_name',
        key: 'zone_name',
        align: 'left',
        disablePadding: false,
        label: translate('custom.zone_name'),
        sortable: true
    }
]

class Tickets extends react.Component {

    constructor(props) {
        super(props);

        this.state = {

            // for filters
            events: [],

            tickets: [],
            count: 0,
            skip: 0,
            limit: 50,
            limitOptions: [10, 50, 100, 250, 500],
            sort_key: '',
            sort_order: '',
            search: '',
            is_filter_visible: false
        };
    }

    async componentDidMount() {
        this.getTickets();
    }

    async getTickets() {
        let options = {
            skip: this.state.skip,
            limit: this.state.limit
        }
        
        if (this.state.sort_key) {
            options.sort_key = this.state.sort_key;
            options.sort_order = this.state.sort_order;
        }

        if (this.state.search) {
            options.search = this.state.search;
        }

        let tickets = await getTicketList(options);
        this.setState({
            tickets: tickets.tickets,
            count: tickets.count
        });
    }

    render() {
        return (
            <V2Layout
                currentMenu='tickets'
            >

                <Grid container style={{marginBottom: '10px'}}>
                    <Card style={{width: '100%', padding: '20px'}}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="search-input">{translate('custom.search')}</InputLabel>
                                    <OutlinedInput
                                        id="search-input"
                                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                        label={translate('custom.search')}
                                        value={this.state.search}
                                        onChange={async (e) => {
                                            await this.setState({search: e.target.value});
                                            this.getTickets();
                                        }}
                                    />
                                </FormControl>
                                {/* <IconButton
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({is_filter_visible: !this.state.is_filter_visible});
                                    }}
                                >
                                    <FilterAltIcon />
                                </IconButton> */}
                            </Stack>
                        </Grid>
                        {(this.state.is_filter_visible) ? (
                            <Grid item xs={12}>

                            </Grid>
                        ) : null}
                    </Card>
                </Grid>


                <TableContainer component={Card} style={{maxHeight: '80vh', overflow: 'auto'}}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((headCell) => (
                                    <TableCell
                                        key={headCell.key}
                                        align={headCell.align ? headCell.align : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={(this.state.sort_key === headCell.key) ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : false}
                                    >
                                        {(headCell.sortable) ? (
                                            <TableSortLabel
                                                active={this.state.sort_key === headCell.key}
                                                direction={this.state.sort_key === headCell.key ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : 'asc'}
                                                onClick={async () => {
                                                    await this.setState({
                                                        sort_key: headCell.key,
                                                        sort_order: this.state.sort_key === headCell.key ? (this.state.sort_order === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
                                                    })
                                                    this.getTickets();
                                                }}
                                            >
                                                {headCell.label}
                                                {this.state.sort_key === headCell.key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {this.state.sort_order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            <>
                                                {headCell.label}
                                            </>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tickets.map((row, idx) => (
                                <StyledTableRow key={row._id} hover
                                    onDoubleClick={() => {
                                        this.props.history.push('/v2/admin/tickets/' + row._id);
                                    }}
                                >
                                    <TableCell>{row.event_id.name}</TableCell>
                                    <TableCell>{row.ticket_provider}</TableCell>
                                    <TableCell align="center"><Chip label={row.ticket.bar_code} style={{width: '100%'}} /></TableCell>
                                    {/* <TableCell>{row.ticket.token}</TableCell> */}
                                    <TableCell>
                                        {(row.view_qr) ? (
                                            <>
                                                <QRCode
                                                    value={row.ticket.token}
                                                />
                                                <br />
                                                <Chip
                                                    label={translate('custom.hide_qr')}
                                                    onClick={() => {                                                        
                                                        let tickets = this.state.tickets;
                                                        tickets[idx].view_qr = !tickets[idx].view_qr;
                                                        this.setState({tickets: tickets});
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <Chip
                                                label={translate('custom.view_qr')}
                                                onClick={() => {                                                        
                                                    let tickets = this.state.tickets;
                                                    tickets[idx].view_qr = !tickets[idx].view_qr;
                                                    this.setState({tickets: tickets});
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>{row.ticket.event_name}</TableCell>
                                    <TableCell>{row.ticket.zone_name}</TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12}> 
                        <TablePagination
                            component="div"
                            count={this.state.count}
                            page={this.state.skip / this.state.limit}
                            onPageChange={async (event, page) => {
                                await this.setState({
                                    skip: page * this.state.limit
                                })
                                this.getTickets();
                            }}
                            rowsPerPage={this.state.limit}
                            onRowsPerPageChange={async (event) => {
                                await this.setState({
                                    limit: event.target.value
                                })
                                this.getTickets();
                            }}
                            rowsPerPageOptions={this.state.limitOptions}
                        />
                    </Grid>
                </Grid>
            </V2Layout>
        )
    }
}

export default Tickets;
