import React, { Component } from 'react';
import '../css/App.css';

import 'whatwg-fetch';
import { languageCheckAndReload, request } from '../util';

import Button  from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import  { Redirect } from 'react-router-dom';
import Header from "../components/Header";
import config from "../config";
import {translate} from "../i18n/customI18nProvider";
import { clear, getRight, getToken, setRight, setToken } from '../services/storage';

export default class Login extends Component {
    constructor(){
        super();
        this.state = {
            token: getToken(),
            right: getRight(),
            username: "",
            password: "",
            eventName: "",
            loading: true
        };
    }

    async componentDidMount() {

        // get the url params
        let pathname = window.location.pathname;
        let pathname_split = pathname.split('/');
        let url_token = null;
        if (pathname_split.length > 2) {
            url_token = pathname_split[2];
        }

        // get the query parameters
        let urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get('token');
        
        if (token || url_token) {
            await this.setState({token: token ? token : url_token});
        }
        token = this.state.token;
        if(token) {
            // fetch the user data
            let response = await request('/users/me', 'GET', token, null);
            if (response.error) {
                clear();
                await this.setState({token: null, right: null});
                window.location.href = '/login';
                return;
            }
            if (response.right) {
                setToken(token);
                setRight(response.right);
                await this.setState({token: token, right: response.right});
            }
        }
        await this.setState({loading: false});

    }

    login = async () => {
        if(!this.state.token) {
            var response = await request('/users/login', 'POST', null, {email: this.state.username, password: this.state.password}, false);
            if(response.incorrect)
                return alert(translate('custom.wrong_username_or_password'));
            if(response.right < config.permissions.SERVER.value)
                return alert(translate('custom.user_doesnt_have_permission'));
            if(!response.token)
                return alert(translate('custom.unknown_error'));
            setToken(response.token);
            setRight(response.right);
            await this.setState({token: response.token});
        }
    };

    render() {
        if (this.state.loading) {
            return <></>
        }
        if(!this.state.token)
            return this.login_render();
        else {
            let right = getRight();
            if(right >= config.permissions.SUPERADMIN.value) {
                return (<Redirect to='/admin' />);
            } else {
                return (<Redirect to='/statistics' />);
            }
        }
    }
    login_render() {
        return (
            <Header
                title="Login"
                name={this.state.eventName}
                noSide={true}
            >
                <div style={{
                   display: "flex",
                   flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems:"center",
                    padding: 20
                }}>
                    <TextField
                        style={{width: 200}}
                        autoFocus={true}
                        label={translate('custom.username')}
                        value={this.state.username}
                        onChange={(event) => {this.setState({username: event.target.value.toLocaleLowerCase()})}}
                    />
                    <TextField
                        style={{width: 200}}
                        type="password"
                        label={translate('custom.password')}
                        onChange={(event) => {this.setState({password: event.target.value})}}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                event.preventDefault();
                                this.login();
                            }
                        }}
                    />
                    <Button
                        color="primary"
                        style={{marginTop: 20, width: 200}}
                        onClick={this.login}
                    >
                        {translate('custom.validate')}
                    </Button>
                </div>
            </Header>
        );
    }
}
