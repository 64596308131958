import { Button, Chip, Grid } from "@mui/material";
import React from "react";
import { ChipField, Datagrid, FunctionField, List, NumberField, ReferenceField, ResourceContextProvider, TextField } from "react-admin";
import { translate } from "../../i18n/customI18nProvider";
import config from "../../config";
import { convertMessageCodeToMessage, downloadFileFromResponse, getRequestOptions } from "../../util";


const downloadSmartphoneTransactions = async (smartphoneNumber) => {
    let response = await fetch(config.apiUrl + '/smartphones/' + smartphoneNumber + '/transactions/export', getRequestOptions('GET'));
    if(response.status !== 200) {
        let responseJson = await response.json();
        Swal.fire({
            title: translate('custom.error'),
            text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
            icon: 'error',
            confirmButtonText: translate('custom.ok'),
            confirmButtonColor: config.baseColor
        });
        return;
    } else {
        await downloadFileFromResponse(response, translate('custom.smartphone') + ' - ' + translate('custom.transactions') + ' - ' + smartphoneNumber + '.xlsx', false);
    }
}

const TransactionsTable = (props) => {

    let currentPath = window.location.href;
    currentPath = currentPath.split('#')[1];
    let smartphoneNumber = currentPath.split('/')[2];
    
    return (
        <>

            <Grid item xs={12} style={{textAlign: 'right'}}>
                <Button
                    onClick={() => {
                        downloadSmartphoneTransactions(smartphoneNumber)
                    }}
                    variant="outlined"
                    style={{borderColor: config.baseColor, color: config.baseColor}}
                >
                    {translate('custom.export')}
                </Button>
            </Grid>

            <Grid item xs={12}>
                <ResourceContextProvider value={"transactions"}>
                    <List
                        actions={null}
                        // syncWithLocation
                        basePath={"/transactions"}
                        filter={{smartphone_number: smartphoneNumber}}
                        bulkActionButtons={false}
                        title={' '}
                    >
                        <Datagrid
                            bulkActionButtons={false}
                        >
                            <FunctionField
                                label={translate('custom.transaction_type')}
                                render={record => translate(`custom.${record.type}`)}
                            />
                            <TextField label={translate('custom.date')} source="date" />
                            <ReferenceField
                                label={translate('custom.point_of_sale')}
                                reference="bars"
                                source="bar_id"
                                sortable={false}
                                link={(record, reference) => `/bars/${record.bar_id}`}
                            >
                                <ChipField source="name" />
                            </ReferenceField>
                            <NumberField
                                label={translate('custom.amount')}
                                source="amount"
                                locales={translate('custom.OPTIONS.i18n_number_format')}
                                options={{ style: 'decimal', minimumFractionDigits: 2 }}
                            />
                            <FunctionField
                                label={translate('custom.client')}
                                render={record => {
                                    return (
                                        <Chip
                                            label={record.client_uid}
                                            onClick={() => {
                                                window.open(`/admin#/clients/${record.client_uid}`, '_blank');
                                            }}
                                        />
                                    )
                                }}
                            />
                        </Datagrid>
                    </List>
                </ResourceContextProvider>
            </Grid>
        </>
    )
}

class SmartphoneTransactionsTable extends React.Component {

    render() {
        return (
            <Grid container spacing={2}>
                <TransactionsTable />
            </Grid>
        )
    }

}

export default SmartphoneTransactionsTable;
