import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getTokenList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/tokens' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTokenDetails = async (device_id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/tokens/' + device_id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const saveTokenDetails = async (token_id, data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.put( config.apiV2Url + '/tokens/' + token_id, data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const forceSaveTokenDetails = async (token_id, data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.apiV2Url + '/tokens/' + token_id + '/force', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const createToken = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/tokens', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const forceCreateToken = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/tokens/force', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteToken = async (id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.apiV2Url + '/tokens/' + id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const validateSaveTokenDetails = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/tokens/validate', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTokenExists = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/tokens/exists' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getTokenList,
    getTokenDetails,
    saveTokenDetails,
    forceSaveTokenDetails,
    createToken,
    forceCreateToken,
    deleteToken,
    validateSaveTokenDetails,
    getTokenExists
}