import React, { Component } from 'react';
import '../css/App.css';

import 'whatwg-fetch';

import Button  from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {
    Link
} from 'react-router-dom'
import Header from "../components/Header";
import { translate } from '../i18n/customI18nProvider';
import config from '../config';
import { languageCheckAndReload } from '../util';
import {request} from "../util";

export default class Home extends Component {
    constructor(){
        super();
        this.state = {
            number: "",
        };
    }

    render() {
        return (

            <Header
                title="Login"
                noSide={true}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems:"center",
                    padding: 20
                }}>
                    <TextField
                        style={{width: 200}}
                        autoFocus={true}
                        label={translate('custom.bracelet_number')}
                        onChange={(event) => {this.setState({number: event.target.value})}}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                window.location.replace('/client/' + this.state.number);
                            }
                        }}
                    />
                    <Button
                        color="primary"
                        // component={Link}
                        // to={'/client/' + this.state.number}
                        style={{marginTop: 20, width: 200}}
                        onClick={async () => {

                            var response = await fetch(config.apiUrl + "/clients/short/" + this.state.number);
                            response = await response.json();

                            if(response.error) {
                                alert(response.error);
                                return;
                            }

                            window.location.replace('/client/' + this.state.number);
                        }}
                    >
                        {translate('custom.validate')}
                    </Button>
                </div>
            </Header>
        );
    }
}
