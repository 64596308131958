import React from 'react';
import config from '../config';
import {SelectInput} from 'react-admin';
import {getChoices} from "../util";
import { getRight } from '../services/storage';

const RightInput = (props) => {
        let choices = getChoices(config.permissions);
        let right = getRight();
        choices = choices.filter((choice) => {
                return choice.id <= right;
        });
        return (
                <SelectInput {...props} choices={choices} />
        )
};

export default RightInput;