import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { translate } from '../../i18n/customI18nProvider';
import { getChoices, request } from '../../util';
import config from '../../config';
import { validatePositiveNumber } from '../../validators/number_validators';
import Swal from 'sweetalert2';
import {
    FileInput,
    FileField,
    SimpleForm,
    Toolbar,
    SelectInput,
    NumberInput,
    useRefresh
} from 'react-admin';
import { convertMessageCodeToMessage } from '../../util';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: 'whitesmoke',
    // border: '2px solid #000',
    borderRadius: '10px',
    // boxShadow: '10px 10px 5px #8d9093',
    padding: '20px',
  },
};

const ClientImportExcelToolbar = props => (
    <Toolbar {...props} style={{visibility: 'hidden', display: 'none'}} ></Toolbar>
);

class ClientImportExcelClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            entrances: [],
            entrance_choices: [],
            selected_entrance: "",
            age_choices: getChoices(config.entrance_ages),
            selected_age: "",
            amount: 0,
            selected_file: null,
            selected_file_buffer: null,
            import_enabled: true,
            progress_percentage: 0
        }

        this.importClients = this.importClients.bind(this);
    }

    async componentDidMount() {

        try {
            let entrances = await request('/entrances/all', 'GET', null);
            entrances = entrances.filter(entrance => ((entrance.type === config.entrance_types.ENTRANCE.value) || (entrance.type === config.entrance_types.UNPAID.value)));
            this.setState({entrances: entrances});
            let entrance_choices = entrances.map(entrance => ({id: entrance._id, name: entrance.name}));
            this.setState({entrance_choices: entrance_choices});
        } catch(e) {
            console.log(e.message)
        }

    }

    handleOpen = () => {
        this.setState({open: true});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleFileChange = async (e) => {

        if (e) {
            this.setState({
                import_enabled: false
            })
            var reader = new FileReader();
            reader.onload = (e) => {
                let arrayBuffer = reader.result;
                let uInt8Array = new Uint8Array(arrayBuffer);
                this.setState({selected_file_buffer: (uInt8Array.toString()).split(','), import_enabled: true});
            }
            reader.readAsArrayBuffer(e);
            this.setState({selected_file: e})
        } else {
            this.setState({selected_file: null});
            this.setState({selected_file_buffer: null});
        }
    }

    delay = async (ms) => {
        await this.timer(ms);
    }

    timer = (ms) => {
        return new Promise(res => setTimeout(res, ms));
    }

    async importClients() {
        
        if(!this.state.selected_file || !this.state.selected_entrance || !this.state.amount) {
            alert(translate('custom.please_fill_all_fields'));
            return;
        }

        this.handleClose();

        let entrance = this.state.entrances.find(entrance => entrance._id === this.state.selected_entrance);
        if(Number(this.state.amount) < Number(entrance.price)) {
            let c = await Swal.fire({
                icon: 'warning',
                title: translate('custom.amount_is_less_than_entrance_price'),
                text: translate('custom.are_you_sure_you_want_to_continue'),
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: translate('custom.yes'),
                cancelButtonText: translate('custom.no'),
                confirmButtonColor: config.baseColor
            });
            if(!c.isConfirmed)
                return;
        }

        const inProgressSwal = Swal.fire({
            title: translate('custom.processing'),
            text: '0 %',
            allowOutsideClick: false,
            showCancelButton: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        
        try {
            let response = await request('/clients/import/excel', 'POST', null, {
                'entrance_id': this.state.selected_entrance,
                'amount': Number(this.state.amount),
                'age': this.state.selected_age,
                'excel_file': this.state.selected_file,
                'excel_file_buffer': this.state.selected_file_buffer
            });

            if(response.status !== 200) {
                inProgressSwal.close();
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? (convertMessageCodeToMessage(response.error_code)) : (response.error ? response.error : translate('custom.unknown_error')),
                    icon: 'error',
                    confirmButtonText: translate('custom.ok'),
                    confirmButtonColor: config.baseColor
                });
            } else if(response.status === 200) {
                let import_id = response?.data?.import_id;
                let total = 0;
                let success = 0;
                let failure = 0;
                let skipped = 0;
                do {
                    let import_status_res = await request('/clients/import/excel/' + import_id, 'GET', null);
                    total = import_status_res.data.band_count;
                    success = import_status_res.data.success;
                    failure = import_status_res.data.failure;
                    skipped = import_status_res.data.skipped;
                    inProgressSwal.update({
                        text: String(parseInt((success + failure + skipped) * 100 / total)) + ' %',
                    });
                    Swal.showLoading();
                    await this.delay(5000);
                } while ((success + failure + skipped) != total);

                inProgressSwal.close();
                Swal.fire({

                    title: translate('custom.import_completed'),
                    text: translate('custom.success') + ": " + success + "\n"  +
                            translate('custom.failure') + ": " + failure + "\n" +
                            translate('custom.skipped') + ": " + skipped + "\n" +
                            translate('custom.import_id') + ": " + import_id,
                    icon: 'success',
                    confirmButtonText: translate('custom.ok'),
                    confirmButtonColor: config.baseColor
                });
                if(success > 0) {
                    this.props.refresh();
                }
            }
        } catch(e) {
            inProgressSwal.close();
            Swal.fire({
                title: translate('custom.error'),
                text: e.message ? e.message : translate('custom.unknown_error'),
                icon: 'error',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            });
        }
    }

    render() {
        return (
            <div>
                <Button
                    color="primary"
                    style={{marginLeft: '10px'}}
                    // onClick={this.importClients}
                    onClick={this.handleOpen}
                    variant="contained"
                    startIcon={<PostAddIcon />}
                    size="small"
                >
                    {translate('custom.import')}
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    // className={classes.modal}
                    style={styles.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        {/* <div className={classes.paper}> */}
                        <div style={styles.paper}>


                            <SimpleForm toolbar={<ClientImportExcelToolbar />} >

                                <SelectInput
                                    source="entrance"
                                    label={translate('custom.entrance')}
                                    choices={this.state.entrance_choices}
                                    value={this.state.selected_entrance}
                                    onChange={e => this.setState({selected_entrance: e.target.value})}
                                    fullWidth={true}
                                    variant="outlined"
                                />

                                <NumberInput
                                    source="amount"
                                    label={translate('custom.amount')}
                                    value={this.state.amount}
                                    step={1}
                                    validate={validatePositiveNumber}
                                    onChange={e => this.setState({amount: e.target.value})}
                                    fullWidth={true}
                                    variant='outlined'
                                    onWheel={(e) => e.target.blur()}
                                />

                                <SelectInput
                                    source="age"
                                    label={translate('custom.age')}
                                    choices={this.state.age_choices}
                                    value={this.state.selected_age}
                                    onChange={e => this.setState({selected_age: e.target.value})}
                                    fullWidth={true}
                                    variant="outlined"
                                />

                                <FileInput
                                    source="files"
                                    // label="Import Clients"
                                    label={translate('custom.import_clients_by_providing_uid_in_excel_format')}
                                    // accept=".csv"
                                    multiple={false}
                                    onChange={this.handleFileChange}
                                >
                                    <FileField source="src" title='title' />
                                </FileInput>

                                <Button
                                    color="primary"
                                    onClick={this.importClients}
                                    variant="contained"
                                    size="small"
                                    fullWidth={true}
                                    disabled={!this.state.import_enabled}
                                >
                                    {translate('custom.import')}
                                </Button>

                            </SimpleForm>

                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

}

const ClientImportExcel = (props) => {
    const refresh = useRefresh();
    return <ClientImportExcelClass {...props} refresh={refresh} />
}
export default ClientImportExcel;
