import react from 'react';
import V2Layout from './Layout';
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, LinearProgress, Paper, Stack, TextField } from '@mui/material';
import config from '../../config';
import { translate } from '../../i18n/customI18nProvider';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import { getEventList } from '../../services/event.service';
import { getRight } from '../../services/storage';
import { createToken, forceCreateToken, forceSaveTokenDetails, getTokenDetails, saveTokenDetails, validateSaveTokenDetails } from '../../services/token.service';

class AddEditToken extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_right: getRight(),
            events: [],
            title: translate('custom.tokens'),
            loading: false,
            form_error: {},
            // ------
            event: '',
            token: '',
            authorized_terminals: [],
            permissions: [],
            force: false
        }
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount() {
        // get the mode, if /add, then create a new token
        // if /:token_id, then fetch the token details
        let mode = this.props?.match?.params?.token_id ? 'edit' : 'add';
        let token_id = this.props?.match?.params?.token_id ? this.props.match.params.token_id : null;
        await this.setState({
            loading: true,
            mode,
            token_id,
            title: mode === 'add' ? translate('custom.add_token') : translate('custom.edit_token')
        });

        await this.setEvents();

        if (mode === 'edit')
            await this.getToken();
            
        
        this.setState({ loading: false });
    }   

    async getToken() {
        // if token_id, fetch token details
        let token = await getTokenDetails(this.state.token_id);
        if (token.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: token.error_code ? convertMessageCodeToMessage(token.error_code) : token.error
            });
            return;
        }
        token = token.token;

        await this.setStateAsync({
            event: token.event_id ? { id: token.event_id._id, label: token.event_id.name } : '',
            token: token.token ? token.token : '',
            authorized_terminals: token.authorized_terminals ? token.authorized_terminals : [],
            permissions: token.permissions ? token.permissions.map((permission) => ({id: permission, label: permission})) : [],
        });
    }

    async setEvents() {
        let events = await getEventList({
            minimal: true
        });

        if (events.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: events.error_code ? convertMessageCodeToMessage(events.error_code) : events.error
            });
            return;
        }

        let eventsArray = events.events.map((event) => {
            return {
                id: event._id,
                label: event.name
            }
        });

        await this.setStateAsync({ events: eventsArray });
    }

    async save(save_and_add=false) {

        console.log("Save and add", save_and_add)

        if (!this.isFormValid()) return;

        console.log("Force", this.state.force)

        if (!this.state.force && !await this.validateTokenForm()) return;

        console.log("Force", this.state.force)

        let payload = {
            event_id: this.state.event.id,
            token: this.state.token,
            authorized_terminals: this.state.authorized_terminals,
            permissions: this.state.permissions.map((permission) => permission.id)
        }

        let response;

        if (this.state.mode === 'add') {
            if (this.state.force)
                response = await forceCreateToken(payload);
            else
                response = await createToken(payload);
        } else if (this.state.mode === 'edit') {
            if (this.state.force)
                response = await forceSaveTokenDetails(this.state.token_id, payload);
            else
                response = await saveTokenDetails(this.state.token_id, payload);
        }


        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error
            });
            return;
        }

        if (save_and_add) {
            this.resetForm();
        } else {
            this.props.history.push(`/v2/admin/tokens`);
        }

        Swal.fire({
            icon: 'success',
            title: translate('custom.success'),
            text: translate('custom.saved_successfully'),
            showConfirmButton: false,
            timer: 1500
        });


    }

    resetForm() {
        this.setState({
            event: '',
            token: '',
            authorized_terminals: [],
            permissions: []
        });
    }

    isFormValid(key=null) {

        let form_error = this.state.form_error;

        if (!key || key == 'event') {
            if (this.state.event === '' || !this.state.event) {
                form_error['event'] = translate('custom.required');
            } else {
                delete form_error['event'];
            }
        }

        if (!key || key == 'authorized_terminals') {
            delete form_error['authorized_terminals'];
        }
        
        if (Object.keys(form_error).length > 0) {
            this.setState({ form_error });
            return false;
        } else {
            this.setState({ form_error: {} });
            return true;
        }

    }

    validateTokenForm = async () => {
        let form_error = this.state.form_error;
    
    
        let payload = {
            "authorized_terminals" : this.state.authorized_terminals
        }
        
        if (this.state.token_id) {
            payload["_id"] = this.state.token_id;
        }
    
        let token_validate = await validateSaveTokenDetails(payload);
    
        if (token_validate?.authorized_terminals) {
            if (token_validate.authorized_terminals?.length > 0) {
                form_error['authorized_terminals'] = '';
                for (let i=0; i<token_validate.authorized_terminals.length; i++) {
                    if (form_error['authorized_terminals'] != '') {
                        form_error['authorized_terminals'] += ' & ';
                    }
                    form_error['authorized_terminals'] += translate('custom.terminal') + ' - ' + token_validate.authorized_terminals[i].terminal + ' ' + translate('custom.is_already_taken_by') + ' ' + token_validate.authorized_terminals[i].event_name;
                }
            }
        }

        this.setState({ form_error });

        if (Object.keys(form_error).length > 0) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <V2Layout
                currentMenu='tokens'
                title={this.state.title}
            >

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Paper sx={{mt: 2, padding: 2}}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} lg={4} xl={4}>
                            <Autocomplete
                                disablePortal
                                id="event-label"
                                options={this.state.events}
                                value={this.state.event}
                                renderInput={(params) => 
                                    <TextField
                                        {...params}
                                        label={translate('custom.event')}
                                        error={this.state.form_error['event'] ? true : false}
                                        helperText={this.state.form_error['event']}
                                    />
                                }
                                getOptionKey={(option) => option.id}
                                onChange={async (e, value) => {
                                    await this.setStateAsync({ event: value });
                                    await this.isFormValid('event');
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} lg={4} xl={4}>
                            <TextField fullWidth label={translate('custom.token')} variant='outlined'
                                value={this.state.token}
                                onChange={async (e) => {
                                    await this.setState({ token: e.target.value })
                                }}
                                helperText={translate('custom.TEXT.token_left_empty_description')}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Autocomplete
                                disablePortal
                                disableCloseOnSelect
                                multiple
                                freeSolo
                                id="authorized-terminals-label"
                                options={[]}
                                value={this.state.authorized_terminals}
                                renderInput={(params) => 
                                    <TextField
                                        {...params}
                                        label={translate('custom.authorized_terminals')}
                                        error={this.state.form_error['authorized_terminals'] ? true : false}
                                        helperText={this.state.form_error['authorized_terminals']}
                                    />
                                }
                                onChange={async (e, value) => {
                                    await this.setState({ authorized_terminals: value });
                                    await this.isFormValid('authorized_terminals');
                                }}
                            />
                        </Grid>


                        <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Autocomplete
                                disablePortal
                                multiple
                                id="event-label"
                                options={config.token_permissions.map((permission) => {
                                    return {
                                        id: permission.id,
                                        label: permission.name
                                    }
                                })}
                                value={this.state.permissions}
                                renderInput={(params) => <TextField {...params} label={translate('custom.permissions')} />}
                                getOptionKey={(option) => option.id}
                                isOptionEqualToValue={(option, value) => { return option.id === value.id }}
                                onChange={async (e, value) => {
                                    console.log("Value", value)
                                    await this.setStateAsync({ permissions: value });
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} sx={{mt: 2, justifyContent: 'space-around'}}>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.force}
                                                onChange={(e) => {
                                                    this.setState({ force: e.target.checked, form_error: {}});
                                                }}
                                            />
                                        }
                                        label={translate('custom.force')}
                                    />
                                </FormGroup>
                                <Button variant='contained' color='primary'
                                    onClick={async () => {
                                        await this.save();
                                    }}
                                    disabled={this.state.loading}
                                >
                                    {translate('custom.save')}
                                </Button>
                                <Button variant='outlined' color='primary'
                                    onClick={async () => {
                                        await this.save(true);
                                    }}
                                    disabled={this.state.loading}
                                >
                                    {translate('custom.save_and_add')}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </V2Layout>
        );
    }
}

export default AddEditToken;
