import React, {Component} from 'react';
import ticket from '../images/ticket.png';
import Header from "../components/Header";
import { QrReader } from 'react-qr-reader';

import Button  from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { nativeTranslate as translate } from "../i18n/customI18nProvider";

import { convertMessageCodeToMessage, request } from "../util";
import Swal from 'sweetalert2';
import config from '../config';
import { Grid } from '@material-ui/core';

class Ticket extends Component {

    constructor(props){
        super(props);
        this.state = {
            ticket_id: "",
            loading: false,
            scan_qr: false
        };
    }

    async componentDidMount() {
        let pathname = window.location.pathname;
        let pathname_split = pathname.split('/');
        let ticket_id = null;
        if (pathname_split.length > 2) {
            ticket_id = pathname_split[2];
        }
        if (ticket_id) {
            await this.setState({
                ticket_id: ticket_id
            });
            this.validate();
        }
    }

    validate = async () => {

        this.setState({loading: true});

        let response = await request('/tickets/'+this.state.ticket_id, 'GET');

        if(response.error || !response.short_id) {
            this.setState({loading: false});
            Swal.fire({
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                icon: 'error',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            }).then(() => {
                window.location.href = '/ticket';
            })
        } else {
            this.setState({loading: false});
            window.location.href = '/client/' + response.short_id;
        }
    };

    render() {

        return (

            <Header
                title={translate('custom.ticket_details')}
                noSide={true}
                loading={this.state.loading}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems:"center",
                    padding: 20
                }}>

                    <Button
                        color="primary"
                        style={{marginTop: 20, width: 200}}
                        onClick={() => {this.setState({scan_qr: !this.state.scan_qr})}}
                        variant='contained'
                    >
                        {this.state.scan_qr ? translate('custom.cancel_scan') : translate('custom.scan_ticket')}
                    </Button>

                    <br /><br />

                    {this.state.scan_qr ? (
                        <>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={8} md={6} lg={6} xl={6} align="center">
                                    <video id='qr-reader-video' style={{ width: '100%', height: 'auto', border: '1px solid gray', borderRadius: '20px' }}>
                                        {translate('custom.unable_to_load_preview')}
                                    </video>
                                </Grid>
                            </Grid>
                            <QrReader
                                onResult={(result, error) => {
                                if (!!result) {

                                    // split the result by '/' and get the last part
                                    let qrCode = result?.text.split('/').pop();

                                    this.setState({ticket_id: qrCode, scan_qr: false});
                                    this.validate();
                                }

                                if (!!error) {
                                    console.info(error);
                                }
                                }}
                                videoId='qr-reader-video'
                                style={{ width: '400px', height: '400px' }}
                                constraints={{facingMode: 'environment'}}
                            />

                            <br /><br />
                        </>
                    ) : null}

                    <p style={{color: 'gray'}}>
                        {translate('custom.or')}
                    </p>

                    <br /><br />

                    <TextField
                        style={{width: 200, textAlign: "center"}}
                        autoFocus={true}
                        label={translate('custom.enter_ticket_number')}
                        onChange={(event) => {this.setState({ticket_id: event.target.value})}}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.validate();
                            }
                        }}
                        value={this.state.ticket_id}
                    />
                    <Button
                        color="primary"
                        style={{marginTop: 20, width: 200}}
                        onClick={this.validate}
                    >
                        {translate('custom.validate')}
                    </Button>
                </div>

                <div align="center">
                    <img src={ticket} alt="Sample Ticket" style={{width: "100%", height: "auto"}} />
                </div>

            </Header>
            

        );

    }
}

export default Ticket;