import { clear } from "../services/storage";

export default {
    login: params => Promise.resolve(),
    logout: () => {
        clear();
        window.location.replace('/login');
    },
    checkAuth: params => Promise.resolve(),
    checkError: error => Promise.resolve(),
    getPermissions: params => Promise.resolve(),
};