import React, { useEffect } from 'react';
import {
    List,
    Create,
    Edit,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    SaveButton,
    TextInput,
    SelectInput,
    SelectArrayInput,
    SimpleList,
    SimpleForm,
    FormDataConsumer,
    Filter,
    Pagination,
    useRedirect,
    required,
    NumberInput,
    BooleanInput,
    Toolbar,
    useRecordContext,
    NullableBooleanInput
} from 'react-admin';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Actions from '../components/reactAdmin/Actions';
import { useMediaQuery } from '@material-ui/core';
import { translate } from '../i18n/customI18nProvider';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import config from "../config";
import Grid from '@material-ui/core/Grid';
import { getChoices, request, formatCurrency, formatCurrencyWithCode } from '../util';

import { validateNumber, validateNumberNonRequired } from '../validators/number_validators';
import { getRight, getToken } from '../services/storage';

const ColorIconRender = ({ record }) => {
    return (
        <>
            <Button
                startIcon={<FiberManualRecordIcon style={{color: record.colorCode}} />}
                disabled={true}
                size="small"
                style={{color: 'black'}}
            >
                {translate('custom.colors.' + record.id)}
            </Button>
        </>
    );
};

export const ConsumptionIcon = FastfoodIcon;

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const DepositField = ({ record }) => {
    const redirect = useRedirect();
    let deposit = record?.deposit ? record.deposit : null;
    if(deposit) {
        return (
            <Chip label={deposit.name} style={{margin: 1}} clickable={true} onClick={() => {
                redirect('/deposits/' + deposit._id);
            }} />
        )
    } else {
        return (
            <></>
        )
    }
}


const BarsList = ({ record }) => {
    const redirect = useRedirect();
    let bars = record?.bars ? record.bars : [];
    return (
        bars.map((bar) => (
            <Chip label={bar.name} style={{margin: 1}} clickable={true} onClick={() => {
                redirect('/bars/' + bar._id);
            }} />
        ))
    )
}

const AllConsumptionsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" variant='outlined' alwaysOn />
    </Filter>
);

export const ConsumptionList = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }

    return (
        <>

            <List
                {...props}
                actions={<Actions />}
                filters={<AllConsumptionsFilter />}
                pagination={<PostPagination />}
                perPage={50}
                bulkActionButtons={false}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={(record) => {
                            return record.name
                        }}
                        secondaryText={(record) => {
                            return formatCurrency(record.price);
                        }}
                    />
                ) : (
                    <Datagrid hasBulkActions={false}>
                        <TextField label={translate('custom.name')} source="name" />
                        <NumberField label={translate('custom.price')} source="price" locales={translate('custom.OPTIONS.i18n_number_format')} options={{ style: 'decimal', minimumFractionDigits: 2 }}/>
                        <DepositField label={translate('custom.deposit')} />
                        <BarsList label={translate('custom.point_of_sales')} />
                        <EditButton />
                    </Datagrid>
                )}
            </List>

        </>
    );
}

const DepositTextRender = ({ record, currencyCode }) => {
    return (
        <>
            <Button
                disabled={true}
                size="small"
                style={{color: 'black', textTransform: 'none'}}
            >{record.name + ' ' + translate('custom.of') + ' ' + (currencyCode ? (formatCurrencyWithCode(record.price, currencyCode)) : (formatCurrency(record.price)))}</Button>
        </>
    );
}

const all_consumptions_form = (deposits=[], currencyCode='') => {

    return (
        <FormDataConsumer>
            {({formData}) => {
                return (
                    <>
                        <Grid container spacing={2} style={{width: '100%'}}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                <TextInput
                                    source="name"
                                    label={translate('custom.name')} validate={required()}
                                    autoFocus
                                    style={{"width": "100%", "fontWeight" : "bold"}}
                                    className="bigInput"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={1}>
                                <NumberInput
                                    source="price" label={translate('custom.price')} step={1} validate={validateNumber}
                                    style={{"width": "100%"}}
                                    className="bigInput"
                                    variant="outlined"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                <SelectInput
                                    source="min_age" resettable={true} label={translate('custom.minimum_age')} 
                                    choices={getChoices(config.ages)}
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                <SelectInput
                                    source="type" label={translate('custom.item_type')} validate={required()}
                                    choices={[
                                        {id: 'quantity', name: translate('custom.unit_quantity')},
                                        {id: 'input', name: translate('custom.quantity_to_be_entered')}
                                    ]} defaultValue='quantity' 
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                />
                            </Grid>
                            {formData?.type == 'input' && (
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                    <SelectInput fullWidth={true} source="round" label={translate('custom.round')} choices={getChoices(config.round, 'currency', translate('custom.cents'), currencyCode)} variant="outlined" />
                                </Grid>
                            )}
                            {formData?.type == 'quantity' && (
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                                    <SelectInput
                                        source="deposit"
                                        label={translate('custom.deposit')}
                                        choices={deposits}
                                        resettable={true}
                                        optionText={<DepositTextRender currencyCode={currencyCode} record={formData} />}
                                        style={{"width": "100%"}}
                                        record={formData}
                                        variant="outlined"
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <NumberInput
                                    source="position" label={translate('custom.position')} step={1} validate={validateNumberNonRequired}
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                    helperText={
                                        translate('custom.zero_to_insert_at_first') + '. ' +
                                        translate('custom.leave_blank_to_insert_at_last') + '. ' +
                                        translate('custom.positive_to_insert_from_beginning') + '. ' +
                                        translate('custom.negative_to_insert_from_last') + '.'
                                    }
                                    onWheel={(e) => e.target.blur()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <SelectInput
                                    source="color" label={translate('custom.color')}
                                    fullWidth={true}
                                    choices={config.consumption_colors}
                                    resettable={true}
                                    optionText={<ColorIconRender />}
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <NullableBooleanInput
                                    source="hidden" label={translate('custom.hidden')}
                                    defaultValue={null}
                                    style={{"width": "100%"}}
                                    variant="standard"
                                    trueLabel={translate('custom.yes')}
                                    falseLabel={translate('custom.no')}
                                    nullLabel={translate('custom.do_not_change')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <NullableBooleanInput
                                    source="can_buy_only_with_voucher" label={translate('custom.can_buy_only_with_voucher')}
                                    defaultValue={null}
                                    style={{"width": "100%"}}
                                    variant="standard"
                                    trueLabel={translate('custom.yes')}
                                    falseLabel={translate('custom.no')}
                                    nullLabel={translate('custom.do_not_change')}
                                />
                            </Grid>
                        </Grid>
                    </>
                );
            }}
        </FormDataConsumer>
    )
}


export class ConsumptionCreate extends React.Component {

    constructor() {
        super();

        this.state = {
            bars: [],
            all_bars: false,
            currencyCode: '',
            deposits: []
        };
    }

    async componentDidMount() {
        let bar_choices = [];
        try {

            let general_information = await request('/events/general', 'GET', null);
            if(general_information.error) {
                alert(general_information.error_code ? translate('custom.' + general_information.error_code) : general_information.error);
            } else {
                this.setState({max_consumption_price: general_information.maxConsumptionPrice, currencyCode: general_information.currencyCode});
            }

            let bars = await request('/bars', 'GET', null);
            if(bars) {
                if (bars.error) {
                    alert(bars.error);
                    return;
                }
            
                for (let i = 0; i < bars.length; i++) {
                    bar_choices.push({
                        id: bars[i]._id,
                        name: bars[i].name
                    });
                }
                if(bar_choices.length)
                    bar_choices.sort((a, b) => a.name > b.name);
            }

            this.setState({
                bars: bar_choices
            });

            let deposits = await request('/deposits', 'GET', null);
            if(deposits.error) {
                alert(deposits.error);
            } else {
                this.setState({deposits: deposits});
            }

        } catch(e) {
            console.log("Error", e.message);
        }
    }

    async validateCreateConsumptionForm(values) {
        let errors = {};
        if(values.name && ((values.bars && values.bars.length) || values.all_bars)) {
            try {
                let s_errors = await request('/all_consumptions/validate', 'POST', null, values);
                if(s_errors) {
                    errors = {...errors, ...s_errors.server_errors};
                }
            } catch(e) {
                console.log(e.message);
            }
        }
        return errors;
    }

    render() {

        let consumption_create_view;
        if(getToken() && (getRight()  >= config.permissions.ADMIN.value))
            consumption_create_view = (
                <Create title={translate('custom.create_consumption_in_point_of_sales')} {...this.props} >
                    <SimpleForm variant="standard" warnWhenUnsavedChanges validate={this.validateCreateConsumptionForm} redirect="list"
                        // toolbar={<SaveAndAddNewToolbar />}
                    >

                        {all_consumptions_form(this.state.deposits, this.state.currencyCode)}

                        {/* <AllConsumptionsForm currencyCode={this.state.currencyCode} deposits={this.state.deposits} /> */}

                        <Grid container spacing={2} style={{width: '100%'}}>
                            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                <TextInput
                                    source="name" label={translate('custom.name')} validate={required()}
                                    autoFocus
                                    style={{"width": "100%", "fontWeight" : "bold"}}
                                    className="bigInput"
                                    variant="outlined"
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={1}>
                                <NumberInput
                                    source="price" label={translate('custom.price')} step={1} validate={validateNumber}
                                    style={{"width": "100%"}}
                                    className="bigInput"
                                    variant="outlined"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                <SelectInput
                                    allowEmpty={true} emptyText="Aucun" label={translate('custom.minimum_age')} source="min_age" choices={getChoices(config.ages)}
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                <SelectInput
                                    source="type" label={translate('custom.item_type')} validate={required()}
                                    choices={[
                                        {id: 'quantity', name: 'Quantité unitaire (+ et -)'},
                                        {id: 'input', name: 'Quantité à saisir (0.500)'}
                                    ]} defaultValue='quantity' 
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <SelectInput fullWidth={true} source="round" label={translate('custom.round')} choices={getChoices(config.round)} variant="outlined" />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={1}>
                                <NumberInput
                                    source="position" label={translate('custom.position')} step={1} validate={validateNumberNonRequired}
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                    helperText={
                                        translate('custom.zero_to_insert_at_first') + '. ' +
                                        translate('custom.leave_blank_to_insert_at_last') + '. ' +
                                        translate('custom.positive_to_insert_from_beginning') + '. ' +
                                        translate('custom.negative_to_insert_from_last') + '.'
                                    }
                                    onWheel={(e) => e.target.blur()}
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                                <SelectInput
                                    source="color" label={translate('custom.color')}
                                    choices={config.consumption_colors}
                                    resettable={true}
                                    optionText={<ColorIconRender />}
                                    style={{"width": "100%"}}
                                    variant="outlined"
                                />
                            </Grid> */}
                            <Grid container spacing={2} style={{width: '100%'}}>
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <BooleanInput
                                        source="all_bars" label={translate('custom.all_point_of_sales')}
                                        onChange={(e) => {
                                            this.setState({
                                                all_bars: e
                                            });
                                        }}
                                    />
                                </Grid>
                                {!this.state.all_bars && (
                                    <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
                                        <SelectArrayInput
                                            source="bars" label={translate('custom.point_of_sales')} validate={required()}
                                            choices={this.state.bars}
                                            style={{"width": "100%"}}
                                            variant="outlined"
                                        />
                                    </Grid>
                                )}
                            </Grid>

                        </Grid>
                    </SimpleForm>
                </Create>
            )
        else
            consumption_create_view = (
                <></>
            )

        return (consumption_create_view)

    }

}

const DeleteFromBarsView = () => {

    const record = useRecordContext();

    let bars = record.bars ? record.bars : [];

    let choices = [];
    bars.forEach((bar) => {
        choices.push({
            id: bar._id,
            name: bar.name
        });
    })
        
    return (
        <SelectArrayInput
            source="delete_from_bars" label={translate('custom.delete_from_point_of_sales')}
            choices={choices}
            style={{"width": "100%"}}
            variant="standard"
            helperText={translate('custom.delete_operation_has_high_priority')}
        />
    )

}

const AllConsumptionsEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export class ConsumptionEdit extends React.Component {

    constructor() {
        super();

        this.state = {
            bars: [],
            all_bars: false,
            currencyCode: '',
            deposits: []
        };
    }

    async validateEditConsumptionForm(values) {
        let errors = {};
        let hash = window.location.hash.split('#')[1];
        if(values.name && ((values.bars && values.bars.length) || values.all_bars)) {
            try {
                let s_errors = await request( hash + '/validate', 'POST', null, values);
                if(s_errors) {
                    errors = {...errors, ...s_errors.server_errors};
                }
            } catch(e) {
                console.log(e.message);
            }
        }
        return errors;
    }

    async componentDidMount() {
        let bar_choices = [];
        try {

            let general_information = await request('/events/general', 'GET', null);
            if(general_information.error) {
                alert(general_information.error_code ? translate('custom.' + general_information.error_code) : general_information.error);
            } else {
                this.setState({max_consumption_price: general_information.maxConsumptionPrice, currencyCode: general_information.currencyCode});
            }

            let bars = await request('/bars', 'GET', null);
            if(bars) {
                if (bars.error) {
                    alert(bars.error);
                    return;
                }
            
                for (let i = 0; i < bars.length; i++) {
                    bar_choices.push({
                        id: bars[i]._id,
                        name: bars[i].name
                    });
                }
                if(bar_choices.length)
                    bar_choices.sort((a, b) => a.name > b.name);
            }

            this.setState({
                bars: bar_choices
            });

            let deposits = await request('/deposits', 'GET', null);
            if(deposits.error) {
                alert(deposits.error);
            } else {
                this.setState({deposits: deposits});
            }

        } catch(e) {
            console.log("Error", e.message);
        }
    }

    render() {

        // base64 decode id
        let new_id = this.props?.id ? this.props.id : null

        let consumption_edit_view;
        if(getToken() && (getRight()  >= config.permissions.ADMIN.value))
            consumption_edit_view = (
                <Edit title={translate('custom.edit_consumption_in_point_of_sales')} {...this.props} id={new_id}>
                    <SimpleForm variant="standard" warnWhenUnsavedChanges validate={this.validateEditConsumptionForm}
                        toolbar={<AllConsumptionsEditToolbar />}
                    >

                        {all_consumptions_form(this.state.deposits, this.state.currencyCode)}

                        <Grid container spacing={2} style={{width: '100%'}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectArrayInput
                                    source="bar_ids" label={translate('custom.update_on_point_of_sales')}
                                    choices={this.state.bars}
                                    style={{"width": "100%"}}
                                    variant="standard"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <DeleteFromBarsView />
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Edit>
            )
        else
            consumption_edit_view = (
                <></>
            )

        return (consumption_edit_view)

    }

}