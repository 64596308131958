import React, { Component } from 'react';
import '../css/App.css';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {objectIdFromDate} from "../util";
import {translate} from "../i18n/customI18nProvider";
import {request} from "../util";
import moment from "moment";

function formatDate(date){
    date = date ? new Date(date) : new Date();
    return date.getFullYear() + "-" + ('0' + (date.getMonth()+1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2)
           + "T" + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
}

function formatDateOnly(date) {
    date = date ? new Date(date) : new Date();
    return date.getFullYear() + "-" + ('0' + (date.getMonth()+1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
}

class DatePicker extends Component {

    handleChange = name => event => {
        let value = event.target.value;
        if((new Date(value) === "Invalid Date") || isNaN(new Date(value)) || value === '' )
            return;
        // if(name !== 'dateCurrent')
        //     value = value.slice(0,-2) + '00';
        this.setState({ [name]: value });
    };

    constructor(props){
        super(props);
        this.state = {
            barId: '',
            dateBegin: '',
            dateEnd: '',
            dateCurrent: '',
            minDateBegin: props.minDateBegin,
            maxDateEnd: props.maxDateEnd,
            dayStartTime: props.dayStartTime,
        };
    }

    async componentDidMount() {
        // let parameter_date_begin = await request('/parameters/parameter/dateBegin', 'GET');
        // let parameter_date_end = await request('/parameters/parameter/dateEnd', 'GET');

        // if(!parameter_date_begin.error)
        //     parameter_date_begin = parameter_date_begin['dateBegin'];

        // if(!parameter_date_end.error)
        //     parameter_date_end = parameter_date_end['dateEnd'];

        // this.setState({
        //     minDateBegin: new Date(parameter_date_begin),
        //     maxDateEnd: new Date(parameter_date_end)
        // });
    }

    checkDateValidity() {
        let dateBegin = new Date(this.state.dateBegin);
        let minDateBegin = new Date(this.state.minDateBegin);
        if(dateBegin < minDateBegin) {
            alert(translate('custom.start_date_cannot_be_less_than_event_begin_date'));
            return false;
        }

        let dateEnd = new Date(this.state.dateEnd);
        let maxDateEnd = new Date(this.state.maxDateEnd);
        if(dateEnd > maxDateEnd) {
            alert(translate('custom.end_date_cannot_be_greater_than_event_end_date'));
            return false;
        }

        if(dateBegin > dateEnd) {
            alert(translate('custom.start_date_cannot_be_greater_than_end_date'));
            return false;
        }

        return true;
    }

    dayWiseReport(type=null) {
        let current_date = this.state.dateCurrent;
        let current_date_value = (new Date(current_date));

        let day_start_time_hours = new Date(this.state.dayStartTime).getHours();
        let day_start_time_minutes = new Date(this.state.dayStartTime).getMinutes();
        current_date_value.setHours(day_start_time_hours);
        current_date_value.setMinutes(day_start_time_minutes);
        if(type === "prev")
            current_date_value.setDate(current_date_value.getDate() - 1);
        else if(type === "next")
            current_date_value.setDate(current_date_value.getDate() + 1);
        
        let next_date = current_date;
        let next_date_value = (new Date(next_date));
        next_date_value.setHours(day_start_time_hours);
        next_date_value.setMinutes(day_start_time_minutes);
        if(type === null)
            next_date_value.setDate(next_date_value.getDate() + 1);
        else if(type === "next")
            next_date_value.setDate(next_date_value.getDate() + 2);


        current_date_value = new Date(current_date_value);
        next_date_value = new Date(next_date_value);
        let minDateBegin = new Date(this.state.minDateBegin);
        let maxDateEnd = new Date(this.state.maxDateEnd);
        
        if(current_date_value < minDateBegin)
            current_date_value = minDateBegin;

        if(next_date_value > maxDateEnd)
            next_date_value = maxDateEnd;

        if(current_date_value > next_date_value) {
            alert(translate('custom.data_not_available_for_that_date'));
            return;
        }

        this.setState({
            dateCurrent: formatDateOnly(current_date_value)
        });

        window.location.href = this.props.baseUrl + ((this.state.barId !== '') ? ('/' + this.state.barId) : '') + '/'+ objectIdFromDate(current_date_value) + '/' + objectIdFromDate(next_date_value) + '?datetimeBegin=' + (moment(current_date_value).format("YYYY-MM-DD[T]HH:mm")) + '&datetimeEnd=' + (moment(next_date_value).format("YYYY-MM-DD[T]HH:mm"));
    }

    // Quick Hack to Remove the Deprecation Warning
    UNSAFE_componentWillMount() {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (this.props.dateBegin !== this.state.dateBegin || this.props.dateEnd !== this.state.dateEnd ) {
            this.setState({
                barId: this.props.barId ? this.props.barId : '',
                dateBegin: formatDate(this.props.dateBegin),
                dateEnd: formatDate(this.props.dateEnd),
                dateCurrent: formatDateOnly(this.props.dateBegin)
            });
        }
    }


    render(){
        return (
            <div className={this.props.classes.container}>
                <div className="noPrint">
                    <h1 className={this.props.classes.titleTextStyle}>{translate('custom.data_range')}</h1>
                    <div className={this.props.classes.dates} >
                        {/* <div> */}
                        <TextField
                            id="begin-datetime"
                            color="primary"
                            label={translate('custom.start_date')}
                            type="datetime-local"
                            value={this.state.dateBegin}
                            onChange={this.handleChange('dateBegin')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        
                        <TextField
                            id="end-datetime"
                            color="primary"
                            label={translate('custom.end_date')}
                            type="datetime-local"
                            value={this.state.dateEnd}
                            onChange={this.handleChange('dateEnd')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{marginRight: 15}}
                        onClick={()=>{
                            if(this.checkDateValidity())
                                window.location = this.props.baseUrl + ((this.state.barId !== '') ? ('/' + this.state.barId) : '') + '/'+ objectIdFromDate(new Date(this.state.dateBegin)) + '/' + objectIdFromDate(new Date(this.state.dateEnd)) + '?datetimeBegin=' + this.state.dateBegin + '&datetimeEnd=' + this.state.dateEnd;
                        }}
                        className={this.props.classes.validButtonStyle}
                    >
                        {translate('custom.validate')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={()=>{
                            window.location = this.props.baseUrl;
                        }}
                        className={this.props.classes.resetButtonStyle}
                    >
                        {translate('custom.set_to_default')}
                    </Button>
                </div>
                <div className="noPrint">
                    <h1 className={this.props.classes.titleTextStyle}>{translate('custom.day_wise')}</h1>
                    <div align="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={()=>{
                                this.dayWiseReport('prev');
                            }}
                            className={this.props.classes.validButtonStyle}
                        >
                            &lt;
                        </Button>
                        <TextField
                            id="current-date"
                            color="primary"
                            label="Date"
                            type="date"
                            value={this.state.dateCurrent}
                            onChange={this.handleChange('dateCurrent')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={()=>{
                                this.dayWiseReport('next');
                            }}
                            className={this.props.classes.validButtonStyle}
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={()=>{
                                this.dayWiseReport();
                            }}
                            className={this.props.classes.validButtonStyle}
                        >
                            {translate('custom.validate')}
                        </Button>
                    </div>
                </div>
                <div className="print">
                    <h1 className={this.props.classes.titleTextStyle}>{translate('custom.data_range')}</h1>
                    <p>
                        {new Date(this.state.dateBegin).toLocaleDateString()} {new Date(this.state.dateBegin).toLocaleTimeString()}
                        {' - '}
                        {new Date(this.state.dateEnd).toLocaleDateString()} {new Date(this.state.dateEnd).toLocaleTimeString()}
                    </p>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    container: {
        [theme.breakpoints.up('md')]: {
            width: "80%"
        },
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 20
    },
    titleTextStyle: {
        color: theme.palette.primary.main,
        fontSize: 24,
        bold: true,
        marginBottom: 15
    },
    dates: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    validButtonStyle: {
        border: '1px solid !important',
        borderRadius: '4px !important',
        color: theme.palette.primary.main + ' !important',
        margin: '15px !important',
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important'
    },
    resetButtonStyle: {
        border: '1px solid !important',
        borderRadius: '4px !important',
        color: '#ff1744 !important',
        margin: '15px !important',
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important'
    }
});

DatePicker.defaultProps= {
    baseUrl: '',
    dateBegin: '',
    dateEnd: ''
};

export default withStyles(styles)(DatePicker);