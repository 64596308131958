import React, {Component} from 'react';
import {translate} from "../i18n/customI18nProvider";
import { withStyles } from '@material-ui/core/styles';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

class SmartphonesInEventTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { statistics } = this.props;

        if(statistics && statistics.history_smartphones_in_event && statistics.history_smartphones_in_event.length > 0) {

            // console.log(statistics.history_smartphones_in_event)


            let found = false;
            let row_data = [];
            let total_count = 0;
            let count;
            for(let i=1; i<statistics.history_smartphones_in_event.length; i++) {
                count = parseFloat(statistics.history_smartphones_in_event[i][1]['f']);
                let obj = {
                    date: (new Date(statistics.history_smartphones_in_event[i][0])).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                    count: count
                }
                total_count += count;
                row_data.push(obj);
            }

            if (total_count > 0) {
                found = true;
            }

            if(!found) {
                return (
                    <>
                        <div className="print">
                            <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 350}}></div>
                        </div>
                    </>
                );
            }

            let view = (
                <>
                    <h1 className={this.props.classes.titleTextStyle}>{translate('custom.smartphones_in_event')}</h1>
                    <Box overflow="auto">
                        <Table style={{marginBottom: 20}} >
                            <TableHead className={this.props.classes.table_head} >
                                <TableRow>
                                    <TableCell className={this.props.classes.table_head_amount}>{translate('custom.count')}</TableCell>
                                    <TableCell className={this.props.classes.table_head}>{translate('custom.date')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row_data.map((r_data, r_idx) => (
                                    <TableRow
                                        key={r_idx}
                                    >
                                        <TableCell className={this.props.classes.table_amount} numeric="true">{r_data['count']}</TableCell>
                                        <TableCell className={this.props.classes.table_row}>{r_data['date']}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </>
            );

            return (
                <>
                    <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto"}} id="entrance_count_chart">
                        <div style={{marginTop: 30}}>
                            {view}
                        </div>
                    </div>
                </>
            )

        } else {
            return (
                <>
                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 350}}></div>
                    </div>
                </>
            )
        }
    }
}

const styles = theme => ({
    table_head: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_head_amount: {
        textAlign: 'right',
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_amount: {
        textAlign: 'right',
        fontSize: 15
    },
    table_row: {
        fontSize: 15
    },
    titleTextStyle: {
        color: theme.palette.primary.main,
        fontSize: 24,
        bold: true,
        marginBottom: 15
    }
});

SmartphonesInEventTable.defaultProps= {
    statistics: {}
};

export default withStyles(styles)(SmartphonesInEventTable);
