import React, { cloneElement } from 'react';
import { TopToolbar, CreateButton } from 'react-admin';

export default ({
                    currentSort,
                    className,
                    resource,
                    filters,
                    displayedFilters,
                    exporter, // you can hide ExportButton if exporter = (null || false)
                    filterValues,
                    permanentFilter,
                    hasCreate, // you can hide CreateButton if hasCreate = false
                    basePath,
                    selectedIds,
                    onUnselectItems,
                    showFilter,
                    maxResults,
                    total,
                    ...rest
                }) => {

    let createButton = hasCreate ? <CreateButton basePath={basePath} /> : <></>;

        return (
            <TopToolbar>
                {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
                })}
                {createButton}
            </TopToolbar>
        )
};