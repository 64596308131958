import {gFormatCurrency, gFormatNumber} from "../util";

import React, { Component } from 'react';
import '../css/App.css';

import { withStyles } from '@material-ui/core/styles';
import {CSVLink} from 'react-csv';
import { Chart } from 'react-google-charts';
import {translate} from "../i18n/customI18nProvider";


class ConsumptionList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            general_information: props.general_information,
            currencyCode: props.currencyCode,
            currencySymbol: props.currencySymbol
        };
    }

    render(){

        let currency = this.state.currencySymbol ? this.state.currencySymbol : this.state.currencyCode;

        if(this.props.statistics.length === 0)
            return null;
        let consumptions_format = [[translate('custom.name'), translate('custom.price') + " (" + currency + ")", translate('custom.number'), "Total (" + currency + ")"]];
        let consumptions_csv = [[translate('custom.name'), translate('custom.price') + " (" + currency + ")", translate('custom.number'), "Total (" + currency + ")"]];
            for (let consumption of this.props.statistics) {
                let total = consumption.total_price ? consumption.total_price : consumption.total * consumption._id.price;
                consumptions_format.push([
                    consumption._id.name,
                    gFormatCurrency(consumption._id.price),
                    gFormatNumber(consumption.total),
                    gFormatCurrency(total)
                ]);
                consumptions_csv.push([
                    consumption._id.name,
                    consumption._id.price,
                    consumption.total,
                    total
                ]);
        }
        return (
            <div>
                <div className="noPrint">
                    <h1 className={this.props.classes.titleTextStyle}>{this.props.title}</h1>
                    <CSVLink separator={";"} filename={translate('custom.consumptions') + ".csv"} data={consumptions_csv} >{translate('custom.export')}</CSVLink>
                    <Chart
                        chartType="Table"
                        width="100%"
                        height={this.props.statistics.length * 41 + 30}
                        data={consumptions_format}
                        options={{
                            width: "100%",
                            height: this.props.statistics.length * 41 + 30,
                            alternatingRowStyle: true,
                            sortColumn: 3,
                            sortAscending: false
                        }}
                        graph_id={this.props.title}
                    />
                </div>
                <div className="print">
                    <h1 className={this.props.classes.titleTextStyle} >{translate('custom.consumptions')}</h1>
                    <table className="tablePrint" width="99%">
                        <tbody>
                        {consumptions_format.slice(1).map((row, i) => {
                            let separator = i && (i + 2) % 66 ? // First page, the title use 2 rows
                                <tr key={i}></tr>
                                :[
                                    <tr style={{height: (i !== 0) * 102 /*If first page, no margin*/}} key={i}></tr>,
                                    <tr key={'k' + i/* k is just to have a unique key*/}>
                                        <th>{consumptions_format[0][0]}</th>
                                        <th style={{textAlign: "right"}}>{consumptions_format[0][1]}</th>
                                        <th style={{textAlign: "right"}}>{consumptions_format[0][2]}</th>
                                        <th style={{textAlign: "right"}}>{consumptions_format[0][3]}</th>
                                    </tr>
                                ];

                            return ([
                                separator,
                                <tr key={row[0] + row[1].v + row[3].v} >
                                    <td>{row[0]}</td>
                                    <td style={{textAlign: "right"}}>{row[1].f}</td>
                                    <td style={{textAlign: "right"}}>{row[2].f}</td>
                                    <td style={{textAlign: "right"}}>{row[3].f}</td>
                                </tr>
                            ]);
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up('md')]: {
            marginLeft: 220
        },
    },
    center: {
        marginTop: 64,
        paddingTop: "20px",
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        maxWidth: "1000px",
        backgroundColor: "#fff",
        [theme.breakpoints.up('md')]: {
            padding: 30
        },
    },
    table_head: {
        backgroundColor: "#08607e",
        fontWeight: "bold",
        fontSize: 15,
        color: "#fff"
    },
    table_head2: {
        backgroundColor: "#084056",
        fontWeight: "bold",
        fontSize: 15,
        color: "#fff"
    },
    table_row: {
        fontSize: 15
    },
    container: {
        [theme.breakpoints.up('md')]: {
            marginLeft: 220
        },
    },
    titleTextStyle: {
        color: "#08607e",
        fontSize: 24,
        bold: true,
        marginBottom: 15
    }
});

ConsumptionList.defaultProps= {
    statistics: [],
    title: translate('custom.consumptions')
};

export default withStyles(styles)(ConsumptionList);