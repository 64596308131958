import React, {Component} from 'react';
import { Chart } from 'react-google-charts';
import { convertMessageCodeToMessage } from "../util";
import theme from "./Theme";
import {translate} from "../i18n/customI18nProvider";


const graph_colors_history = [
    theme.palette.primary.light,
    theme.palette.primary.dark
];

const titleTextStyle = {
    color: theme.palette.primary.main,
    fontSize: 24,
    bold: true,
};

class ConsumptionsLineChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currencyCode: props.currencyCode,
            currencySymbol: props.currencySymbol,
        }
    }

    render() {

        const { statistics } = this.props;
        let currency = this.state.currencySymbol ? this.state.currencySymbol : this.state.currencyCode;

        if(statistics && statistics.history_debit && statistics.history_debit.length > 0 && statistics.history_debit[0].length > 0) {

            statistics.history_debit[0][1] = convertMessageCodeToMessage(statistics.history_debit[0][1]);
            statistics.history_debit[0][2] = convertMessageCodeToMessage(statistics.history_debit[0][2]);

            let found = false;
            for(let i=1; i<statistics.history_debit.length; i++) {
                if(statistics.history_debit[i][1]['v'] !== 0 || statistics.history_debit[i][2]['v'] !== 0) {
                    found = true;
                    break;
                }
            }

            if(!found) {
                return (
                    <>
                        <div className="print">
                            <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 500}}></div>
                        </div>
                    </>
                );
            }

            let view = (
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="500px"
                    data={statistics ? statistics.history_debit : []}
                    options={{
                        title: translate('custom.consumptions'),
                        titleTextStyle,
                        vAxis: {
                            title: translate('custom.amount') + ' (' + currency + ')',
                            format: 'short'
                        },
                        hAxis: {
                            format: translate('custom.OPTIONS.chart_date_time_format')
                        },
                        colors: graph_colors_history,
                        backgroundColor: {fill: 'transparent'}
                    }}
                    graph_id="ConsumptionsChart"
                />
            );

            return (
                <div>
                    {view}
                </div>
            )
        } else {
            return (
                <>
                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 500}}></div>
                    </div>
                </>
            )
        }
    }
}

export default ConsumptionsLineChart;