// Created by MJ

import polyglotI18nProvider from 'ra-i18n-polyglot';

import { resolveBrowserLocale } from 'react-admin';

import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import germanMessages from 'ra-language-german';
import enTranslations from '../i18n/en.json';
import frTranslations from '../i18n/fr.json';
import deTranslations from '../i18n/de.json';
import { getLocale, setLocale } from '../services/storage';

const messages = {
    fr: { ...frenchMessages, ...frTranslations },
    en: { ...englishMessages, ...enTranslations },
    de: { ...germanMessages, ...deTranslations },
};

let nativeLocale = resolveBrowserLocale();
let localStorageLocale = getLocale();
if (localStorageLocale === null) {
    localStorageLocale = resolveBrowserLocale();
    // change html attribute lang
    document.documentElement.lang = localStorageLocale;
    setLocale(localStorageLocale);
} else {
    // change html attribute lang
    document.documentElement.lang = localStorageLocale;
}

// export const i18nProvider = polyglotI18nProvider(
//     locale => messages[localStorageLocale] ? messages[localStorageLocale] : ( messages[locale] ? messages[locale] : messages.en ),
//     resolveBrowserLocale()
// );
export const i18nProvider = polyglotI18nProvider(
    locale => messages[localStorageLocale] ? messages[localStorageLocale] : ( messages[locale] ? messages[locale] : messages.en )
);

export const nativeI18nProvider = polyglotI18nProvider(
    locale => messages[nativeLocale] ? messages[nativeLocale] : ( messages[locale] ? messages[locale] : messages.en )
);

export const locale = localStorageLocale;
export const translate = i18nProvider.translate
export const nativeTranslate = nativeI18nProvider.translate