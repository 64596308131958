import react from 'react';
import V2Layout from './Layout';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@mui/material';
import StyledTableRow from '../../components/v2/common/table/StyledTableRow';
import { translate } from '../../i18n/customI18nProvider';
import moment from 'moment/moment';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import config from '../../config';
import SearchIcon from '@mui/icons-material/Search';
import { getRoleFromRight, getRoles } from '../../services/common.service';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getEventList } from '../../services/event.service';
import { deleteToken, getTokenList } from '../../services/token.service';

const tableHeader = [
    {
        id: 'event',
        key: 'event',
        align: 'left',
        disablePadding: false,
        label: translate('custom.event'),
        sortable: false
    },
    {
        id: 'token',
        key: 'token',
        align: 'left',
        disablePadding: false,
        label: translate('custom.token'),
        sortable: false
    },
    {
        id: 'authorized_terminals',
        key: 'authorized_terminals',
        align: 'left',
        disablePadding: false,
        label: translate('custom.authorized_terminals'),
        sortable: false
    },
    {
        id: 'permissions',
        key: 'permissions',
        align: 'left',
        disablePadding: false,
        label: translate('custom.permissions'),
        sortable: false
    },
    {
        id: 'actions',
        key: 'actions',
        align: 'left',  
        disablePadding: false,
        label: translate('custom.actions'),
        sortable: false
    }
]

class Tokens extends react.Component {

    constructor(props) {
        super(props);

        this.state = {
            show_filter: false,
            tokens: [],
            count: 0,
            skip: 0,
            limit: 50,
            limitOptions: [10, 50, 100, 250, 500],
            events: [],
            event: '',
            right: '',
            sort_key: '',
            sort_order: '',
            search: '',
            authorized_terminals: '',
            archived: false,
            deleted_only: false,
            with_deleted: false
        };
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount() {
        this.getTokens();
        this.setEvents();
    }

    async getTokens() {
        let options = {
            skip: this.state.skip,
            limit: this.state.limit
        }
        
        if (this.state.sort_key) {
            options.sort_key = this.state.sort_key;
            options.sort_order = this.state.sort_order;
        }

        if (this.state.search) {
            options.search = this.state.search;
        }

        if (this.state.event) {
            options.event_id = this.state.event.id;
        }

        if (this.state.authorized_terminals) {
            options.authorized_terminals = this.state.authorized_terminals;
        }

        if (this.state.archived) {
            options.archived = this.state.archived;
        }

        if (this.state.deleted_only) {
            options.deleted_only = this.state.deleted_only;
        }

        if (this.state.with_deleted) {
            options.with_deleted = this.state.with_deleted;
        }

        let tokens = await getTokenList(options);
        this.setState({
            tokens: tokens.tokens,
            count: tokens.count
        });
    }

    async setEvents() {
        let events = await getEventList({
            minimal: true
        });

        if (events.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: events.error_code ? convertMessageCodeToMessage(events.error_code) : events.error
            });
            return;
        }

        let eventsArray = events.events.map((event) => {
            return {
                id: event._id,
                label: event.name
            }
        });

        await this.setStateAsync({ events: eventsArray });
    }

    async deleteTokenFromId(id) {
        let c = await Swal.fire({
            title: translate('custom.are_you_sure'),
            text: translate('custom.do_you_want_to_delete_this_token'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: translate('custom.yes'),
            cancelButtonText: translate('custom.no'),
            confirmButtonColor: 'red'
        });
        if (c.isConfirmed) {
            let response = await deleteToken(id);
            if (response.error) {
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor
                });
                return;
            }

            Swal.fire({
                title: translate('custom.success'),
                text: response.message_code ? convertMessageCodeToMessage(response.message_code) : response.message,
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false
            });

            this.getTokens();
        }
    }

    render() {
        return (
            <V2Layout
                currentMenu='tokens'
            >
                <Grid container spacing={2} style={{marginBottom: '10px', justifyContent: 'flex-end'}}>
                    <Grid item>
                        <Button variant='contained' style={{textTransform: 'none'}}
                            onClick={() => {
                                this.props.history.push('/v2/admin/tokens/add');
                            }}
                        >
                            + {translate('custom.token')}
                        </Button>
                    </Grid>
                </Grid>

                {/* <Grid container style={{marginBottom: '10px'}}>
                    <Card style={{width: '100%', padding: '20px'}}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="search-input">{translate('custom.search')}</InputLabel>
                                    <OutlinedInput
                                        id="search-input"
                                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                        label={translate('custom.search')}
                                        value={this.state.search}
                                        onChange={async (e) => {
                                            await this.setState({search: e.target.value});
                                            this.getTokens();
                                        }}
                                    />
                                </FormControl>
                                <Button aria-label="filter"
                                    variant={this.state.show_filter ? 'contained' : 'outlined'}
                                    onClick={() => {
                                        this.setState({show_filter: !this.state.show_filter});
                                    }}
                                >
                                    <FilterAltIcon />
                                </Button>
                            </Stack>
                        </Grid>
                        {(this.state.show_filter) ? (
                            <Grid container spacing={2} sx={{mt: 1}}>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Autocomplete
                                        disablePortal
                                        id="event-label"
                                        options={this.state.events}
                                        value={this.state.event}
                                        renderInput={(params) => <TextField {...params} label={translate('custom.event')} />}
                                        onChange={async (e, value) => {
                                            await this.setStateAsync({ event: value });
                                            this.getTokens();
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id='right-label'>{translate('custom.permission')}</InputLabel>
                                        <Select
                                            labelId='right-label'
                                            fullWidth
                                            label={translate('custom.permission')}
                                            value={this.state.permission}
                                            onChange={async (e) => {
                                                await this.setStateAsync({ right: e.target.value })
                                                await this.getTokens();
                                            }}
                                        >
                                            {(config.token_permissions).map((permission) => {
                                                return <MenuItem value={permission.id} key={permission.id}>{permission.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="authorized-terminal-input">{translate('custom.authorized_terminal')}</InputLabel>
                                        <OutlinedInput
                                            id="authorized-terminal-input"
                                            label={translate('custom.authorized_terminal')}
                                            value={this.state.authorized_terminals}
                                            onChange={async (e) => {
                                                await this.setStateAsync({authorized_terminals: e.target.value});
                                                this.getTokens();
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}></Grid>

                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.archived}
                                                    onChange={async (e) => {
                                                        await this.setStateAsync({ archived: e.target.checked });
                                                        this.getTokens();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.archived')}
                                        />
                                    </FormGroup>
                                </Grid>
                                
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.with_deleted}
                                                    onChange={async (e) => {
                                                        let deleted_only = (e.target.checked) ? false : this.state.deleted_only;
                                                        await this.setStateAsync({ with_deleted: e.target.checked, deleted_only });
                                                        this.getTokens();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.with_deleted')}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.deleted_only}
                                                    onChange={async (e) => {
                                                        let with_deleted = (e.target.checked) ? false : this.state.with_deleted;
                                                        await this.setStateAsync({ deleted_only: e.target.checked, with_deleted });
                                                        this.getTokens();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.deleted_only')}
                                        />
                                    </FormGroup>
                                </Grid>

                            </Grid>
                        ) : null}
                    </Card>
                </Grid> */}


                <TableContainer component={Card} style={{maxHeight: '80vh', overflow: 'auto'}}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((headCell) => (
                                    <TableCell
                                        key={headCell.key}
                                        align={headCell.align ? headCell.align : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={(this.state.sort_key === headCell.key) ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : false}
                                    >
                                        {(headCell.sortable) ? (
                                            <TableSortLabel
                                                active={this.state.sort_key === headCell.key}
                                                direction={this.state.sort_key === headCell.key ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : 'asc'}
                                                onClick={async () => {
                                                    await this.setState({
                                                        sort_key: headCell.key,
                                                        sort_order: this.state.sort_key === headCell.key ? (this.state.sort_order === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
                                                    })
                                                    this.getTokens();
                                                }}
                                            >
                                                {headCell.label}
                                                {this.state.sort_key === headCell.key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {this.state.sort_order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            <>
                                                {headCell.label}
                                            </>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tokens.map((row) => (
                                <StyledTableRow key={row._id} hover
                                    onDoubleClick={() => {
                                        this.props.history.push('/v2/admin/tokens/' + row._id);
                                    }}
                                >
                                    <TableCell>{row.event_id?.name}</TableCell>
                                    <TableCell>{row.token}</TableCell>
                                    <TableCell>
                                        {row.authorized_terminals.map((authorized_terminal) => {
                                            return (
                                                <Chip key={authorized_terminal} label={authorized_terminal} />
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {row.permissions.map((permission) => {
                                            return (
                                                <Chip key={permission} label={translate('custom.' + permission)} />
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton size='small' onClick={() => {
                                            this.props.history.push('/v2/admin/tokens/' + row._id);
                                        }}>
                                            <EditIcon />
                                        </IconButton>
                                        {/* <IconButton size='small' color='error' onClick={() => {
                                            this.deleteTokenFromId(row._id);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12}> 
                        <TablePagination
                            component="div"
                            count={this.state.count}
                            page={this.state.skip / this.state.limit}
                            onPageChange={async (event, page) => {
                                await this.setState({
                                    skip: page * this.state.limit
                                })
                                this.getTokens();
                            }}
                            rowsPerPage={this.state.limit}
                            onRowsPerPageChange={async (event) => {
                                await this.setState({
                                    limit: event.target.value
                                })
                                this.getTokens();
                            }}
                            rowsPerPageOptions={this.state.limitOptions}
                        />
                    </Grid>
                </Grid>
            </V2Layout>
        )
    }
}

export default Tokens;
