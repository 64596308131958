import React from 'react';
import {
    // ArrayField,
    // ChipField,
    // SingleFieldList,
    ArrayInput,
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    required,
    SimpleList,
    Filter,
    SimpleFormIterator,
    SelectInput,
    NumberInput,
    BooleanInput,
    Pagination,
    Toolbar,
    SaveButton,
    useRefresh
} from 'react-admin';
import CalendarViewDay from '@material-ui/icons/CalendarViewDay';
// import RightField from "../fields/RightField";   // commented by MJ
import Actions from '../components/reactAdmin/Actions';
import { Grid, useMediaQuery } from '@material-ui/core';
// import config from "../config";
import { translate } from '../i18n/customI18nProvider';
import { request } from '../util';
import Chip from '@material-ui/core/Chip';
import RestoreIcon from '@material-ui/icons/Restore';
import Button from '@material-ui/core/Button';

import { validatePositiveNumber } from '../validators/number_validators';

export const VoucherPackIcon = CalendarViewDay;

const QuickFilter = ({ label }) => {
    return <Chip style={{margin: 8, marginLeft: 0}} label={translate(label)} />;
};

const VoucherPackListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" variant='outlined' alwaysOn />
        <QuickFilter source="deleted_only" label={'custom.deleted_only'} defaultValue={true} />
        <QuickFilter source="with_deleted" label={'custom.with_deleted'} defaultValue={true} />
    </Filter>
);

const VoucherPackListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CustomButtons = ({record}) => {
    const refresh = useRefresh();
    if(record.deleted) {
        return (
            <>
                <Button
                    color="primary"
                    startIcon={<RestoreIcon />}
                    onClick={async () => {
                        try {
                            await request('/voucher_packs/' + record._id + '/restore', 'POST', null, {});
                            refresh();
                        } catch(e) {
                            console.log(e.message)
                        }
                    }}
                    style={{marginLeft: '10px', marginRight: '10px'}}
                >
                    {translate('custom.restore')}
                </Button>
            </>
        )
    } else {
        return (
            <>
                <EditButton record={record} style={{marginLeft: '10px', marginRight: '10px'}} />
            </>
        )
    }
}

export const VoucherPackList = (props) => {

    // let consumptions_view = (
    //     <ArrayField source="consumptions">
    //         <SingleFieldList>
    //             <ChipField source="name" />
    //         </SingleFieldList>
    //     </ArrayField>
    // );

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }
    
    return (
        <>

            <List
                {...props}
                actions={<Actions />}
                filters={<VoucherPackListFilter />}
                pagination={<VoucherPackListPagination />}
                perPage={50}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.name}
                    />
                ) : (
                    <Datagrid>
                        <TextField label={translate('custom.name')} source="name" />
                        {/* {consumptions_view} */}
                        <CustomButtons />
                    </Datagrid>
                )}
            </List>

        </>
    );
};

const PostTitle = ({ record }) => {
    return <span>Voucher Pack {record ? `"${record.name}"` : ''}</span>;
};

const voucher_form = (isSmall, vouchers) => {
    return (
        <ArrayInput source="vouchers" validate={required()} label={translate('custom.vouchers')}>
            <SimpleFormIterator disableReordering>
                <SelectInput
                    fullWidth={isSmall ? true : false} variant="outlined" label={translate('custom.voucher')} source="voucher_id" choices={vouchers} validate={required()}
                    style={isSmall ? null : {"float": "left", "margin": "10px", "width": "40%"}} autoFocus
                />
                <NumberInput
                    fullWidth={isSmall ? true : false} variant="outlined" source="quantity" label={translate('custom.quantity')} validate={validatePositiveNumber}
                    style={isSmall ? null : {"margin": "10px", "width": "40%"}}
                    onWheel={(e) => e.target.blur()}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};


export class VoucherPackEdit extends React.Component {
    constructor() {
        super();

        this.state = {
            vouchers: [],
            voucher_packs: []
        };
    }

    async componentDidMount() {
        let vocs = [];
        let vouchers = await request('/vouchers', 'GET', null);
        let voucher_packs = await request('/voucher_packs', 'GET', null);
        voucher_packs = voucher_packs ? voucher_packs : [];
        if (vouchers.error) {
            alert(vouchers.error);
            return;
        }
        for(let i=0; i<vouchers.length; i++) {
            vocs.push({
                id: vouchers[i]._id,
                name: vouchers[i].name
            });
        }
        if(vocs.length)
            vocs.sort((a, b) => a.name > b.name);
        this.setState({
            vouchers: vocs,
            voucher_packs
        });
    }

    validateVoucherPackForm = async (values) => {
        const errors = {};
        try {
            const voucher_pack_name = values.name;
            const found = this.state.voucher_packs.find((voc) => voc.name === voucher_pack_name);
            if(found && values.id != found.id) {
                errors.name = translate('custom.this_name_is_already_taken');
            }

            let values_vouchers = values.vouchers ? values.vouchers : [];
            for(let i=0; i<values_vouchers.length; i++) {
                if(!values_vouchers[i].quantity) {
                    if(!errors.vouchers)
                        errors.vouchers = [];
                    if(!errors.vouchers[i])
                        errors.vouchers[i] = {};
                    errors.vouchers[i].quantity = translate('custom.quantity_is_required');
                } else if(values_vouchers[i].quantity < 1) {
                    if(!errors.vouchers)
                        errors.vouchers = [];
                    if(!errors.vouchers[i])
                        errors.vouchers[i] = {};
                    errors.vouchers[i].quantity = translate('custom.quantity_must_be_greater_than_0');
                } else if(values_vouchers[i].quantity != parseInt(values_vouchers[i].quantity)) {
                    if(!errors.vouchers)
                        errors.vouchers = [];
                    if(!errors.vouchers[i])
                        errors.vouchers[i] = {};
                    errors.vouchers[i].quantity = translate('custom.quantity_must_be_an_integer');
                }
            }
        } catch(e) {
            console.log("[Validation Error]", e.message);
        }

        return errors
    };

    render() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <Edit undoable={false} title={<PostTitle />} {...this.props}>
                <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={this.validateVoucherPackForm}>

                    <Grid container spacing={2} style={{width: '100%'}}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <TextInput fullWidth variant="outlined" source="name" label={translate('custom.name')} validate={required()} autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <BooleanInput source="isVisibleInApp" label={translate('custom.visible_in_app')} style={{"margin": "10px"}} />
                        </Grid>
                    </Grid>

                    {voucher_form(isSmall, this.state.vouchers)}

                </SimpleForm>
            </Edit>
        )
    }

}

const SaveAndAddNewToolbar = (props) => {
    const refresh = useRefresh();
    return (
        <Toolbar>
            <SaveButton
                {...props}
                label="ra.action.save"
                redirect="edit"
                submitOnEnter={false}
                style={{marginRight: 10}}
            />
            <SaveButton
                {...props}
                label={translate('custom.save_and_add')}
                redirect={() => {
                    refresh();
                    return 'create';
                }}
                submitOnEnter={true}
            />
        </Toolbar>
    )
}

export class VoucherPackCreate extends React.Component {

    constructor() {

        super();

        this.state = {
            vouchers: [],
            voucher_packs: []
        };
    }

    async componentDidMount() {
        let vocs = [];
        let vouchers = await request('/vouchers', 'GET', null);
        let voucher_packs = await request('/voucher_packs', 'GET', null);
        voucher_packs = voucher_packs ? voucher_packs : [];
        if (vouchers.error) {
            alert(vouchers.error);
            return;
        }
        for(let i=0; i<vouchers.length; i++) {
            vocs.push({
                id: vouchers[i].id,
                name: vouchers[i].name
            });
        }
        if(vocs.length)
            vocs.sort((a, b) => a.name > b.name);
        this.setState({
            vouchers: vocs,
            voucher_packs: voucher_packs
        });
    }

    validateVoucherPackForm = async (values) => {
        const errors = {};
        const voucher_pack_name = values.name;
        const voucher_packs = this.state.voucher_packs;
        try {
            if (voucher_packs) {
                const found = voucher_packs.find((voc) => voc.name === voucher_pack_name);
                if(found) {
                    errors.name = translate('custom.this_name_is_already_taken');
                }
            }

            let values_vouchers = values.vouchers ? values.vouchers : [];
            for(let i=0; i<values_vouchers.length; i++) {
                if(!values_vouchers[i].quantity) {
                    if(!errors.vouchers)
                        errors.vouchers = [];
                    if(!errors.vouchers[i])
                        errors.vouchers[i] = {};
                    errors.vouchers[i].quantity = translate('custom.quantity_is_required');
                } else if(values_vouchers[i].quantity < 1) {
                    if(!errors.vouchers)
                        errors.vouchers = [];
                    if(!errors.vouchers[i])
                        errors.vouchers[i] = {};
                    errors.vouchers[i].quantity = translate('custom.quantity_must_be_greater_than_0');
                } else if(values_vouchers[i].quantity != parseInt(values_vouchers[i].quantity)) {
                    if(!errors.vouchers)
                        errors.vouchers = [];
                    if(!errors.vouchers[i])
                        errors.vouchers[i] = {};
                    errors.vouchers[i].quantity = translate('custom.quantity_must_be_an_integer');
                }
            }

        } catch(e) {
            console.log("[Validation Error]", e.message);
        }
        
        return errors
    };

    render() {

        let isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            isSmall = true;
        }

        return (
            <Create title={translate('custom.create_voucher_pack')} {...this.props}>
                <SimpleForm variant="standard" warnWhenUnsavedChanges validate={this.validateVoucherPackForm}
                    toolbar={<SaveAndAddNewToolbar />} redirect="list"
                >
                    <Grid container spacing={2} style={{width: '100%'}}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <TextInput fullWidth variant="outlined" source="name" label={translate('custom.name')} validate={required()} autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <BooleanInput source="isVisibleInApp" label={translate('custom.visible_in_app')} style={{"margin": "10px"}} />
                        </Grid>
                    </Grid>

                    {voucher_form(isSmall, this.state.vouchers)}
                    
                </SimpleForm>
            </Create>
        )
    }
}