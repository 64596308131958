import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import registerServiceWorker from './registerServiceWorker';

import Home from './screens/Home';
import Statistics from './screens/Statistics';
import Client from './screens/Client';
import ClientEmbed from './screens/ClientEmbed';
import Login from './screens/Login';
import NotFound from './screens/NotFound';
import Pay from './screens/Pay';
import Admin from './screens/Admin';

import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import config from "./config";
import Ticket from "./screens/Ticket";
import Refund from "./screens/Refund";
import Users from './screens/v2/Users';
import AddEditUser from './screens/v2/AddEditUser';
import Devices from './screens/v2/Devices';
import V2Layout from './screens/v2/Layout';
import AddEditDevice from './screens/v2/AddEditDevice';
import Tickets from './screens/v2/Tickets';
import Tokens from './screens/v2/Tokens';
import AddEditToken from './screens/v2/AddEditToken';


const Contactless = () => (
    <Router>
        <Switch>
            <Route exact path={config.baseUrl + "/"} component={Home}/>
            <Route exact path={config.baseUrl + "/client"} component={Home}/>
            <Route exact path={config.baseUrl + "/statistics/:bar"} component={Statistics}/>
            <Route exact path={config.baseUrl + "/statistics/:dateBegin/:dateEnd"} component={Statistics}/>
            <Route exact path={config.baseUrl + "/statistics/:bar/:dateBegin/:dateEnd"} component={Statistics}/>
            <Route exact path={config.baseUrl + "/statistics"} component={Statistics}/>
            <Route exact path={config.baseUrl + "/login"} component={Login}/>
            <Route exact path={config.baseUrl + "/login/:token"} component={Login}/>
            <Route exact path={config.baseUrl + "/ticket"} component={Ticket}/>
            <Route exact path={config.baseUrl + "/ticket/:ticketId"} component={Ticket}/>
            <Route exact path={config.baseUrl + "/refund"} component={Refund}/>
            <Route exact path={config.baseUrl + "/refund/:client"} component={Refund}/>
            <Route exact path={config.baseUrl + "/client:app(/app|)/:client"} component={Client}/>
            <Route exact path={config.baseUrl + "/client/:client/donation"} component={Client}/>
            <Route exact path={config.baseUrl + "/client/:client/pay"} component={Pay}/>
            <Route exact path={config.baseUrl + "/client/:client/embed/:mode"} component={ClientEmbed}/>
            <Route exact path={config.baseUrl + "/event/:event/client/:client"} component={Client}/>
            <Route exact path={config.baseUrl + "/admin"} component={Admin}/>
            <Route exact path={config.baseUrl + "/v2/admin"} component={V2Layout}/>
            <Route exact path={config.baseUrl + "/v2/admin/users"} component={Users} />
            <Route exact path={config.baseUrl + "/v2/admin/users/add"} component={AddEditUser} />
            <Route exact path={config.baseUrl + "/v2/admin/users/:user_id"} component={AddEditUser} />
            <Route exact path={config.baseUrl + "/v2/admin/devices"} component={Devices} />
            <Route exact path={config.baseUrl + "/v2/admin/devices/add"} component={AddEditDevice} />
            <Route exact path={config.baseUrl + "/v2/admin/devices/:device_id"} component={AddEditDevice} />
            <Route exact path={config.baseUrl + "/v2/admin/tickets"} component={Tickets} />
            <Route exact path={config.baseUrl + "/v2/admin/tokens"} component={Tokens} />
            <Route exact path={config.baseUrl + "/v2/admin/tokens/add"} component={AddEditToken} />
            <Route exact path={config.baseUrl + "/v2/admin/tokens/:token_id"} component={AddEditToken} />
            <Route exact path={config.baseUrl + "/404"} component={NotFound} />
            <Route exact path={config.baseUrl + "/:client"} component={Client}/>
            <Route exact path={config.baseUrl + "/:client/donation"} component={Client}/>
            <Redirect to={config.baseUrl + "/404"} />
        </Switch>
    </Router>
)

ReactDOM.render(<Contactless />, document.getElementById('root'));
try {
    registerServiceWorker();
} catch (error) {
    console.warn('Error during service worker registration:', error.message);
}