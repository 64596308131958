import React, {Component} from 'react';
import { Chart } from 'react-google-charts';
import theme from "./Theme";
import {translate} from "../i18n/customI18nProvider";

const graph_colors = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.primary.dark
];

const titleTextStyle = {
    color: theme.palette.primary.main,
    fontSize: 24,
    bold: true,
};

class ConsumptionsColumnChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currencyCode: props.currencyCode,
            currencySymbol: props.currencySymbol,
        }
    }

    render() {

        let currency = this.state.currencySymbol ? this.state.currencySymbol : this.state.currencyCode;
        const { debits } = this.props

        if(debits && debits.length > 1) {

            let view = (
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={debits}
                    options={{
                        title: translate('custom.consumptions'),
                        titleTextStyle,
                        vAxis: {
                            title: translate('custom.amount') + ' (' + currency + ')',
                            format: 'short'
                        },
                        legend: {position: "none"},
                        colors: graph_colors,
                        backgroundColor: {fill: 'transparent'}
                    }}
                    graph_id="DebitChart"
                />
            );

            return (
                <>
                    {view}
                </>
            );

        } else {
            return (
                <>
                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 350}}></div>
                    </div>
                </>
            );
        }
    }
}

export default ConsumptionsColumnChart;