import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { translate } from '../../i18n/customI18nProvider';
import { request } from '../../util';
import config from './../../config';
import Swal from 'sweetalert2';
import {
    FileInput,
    FileField,
    SimpleForm,
    Toolbar,
    SelectInput,
    NumberInput,
    useRefresh
} from 'react-admin';
import { convertMessageCodeToMessage } from './../../util';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: 'whitesmoke',
    // border: '2px solid #000',
    borderRadius: '10px',
    // boxShadow: '10px 10px 5px #8d9093',
    padding: '20px',
  },
};

const ClientAddEntranceToolbar = props => (
    <Toolbar {...props} style={{visibility: 'hidden', display: 'none'}} ></Toolbar>
);

class ClientAddEntranceClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            entrances: [],
            entrance_choices: [],
            selected_entrance: "",
            amount: 0,
            selected_file: null,
            selected_file_buffer: null,
            import_enabled: true,
            progress_percentage: 0
        }

        this.addEntrance = this.addEntrance.bind(this);
    }

    async componentDidMount() {

        try {
            let entrances = await request('/entrances/', 'GET', null);
            entrances = entrances.filter(entrance => ((entrance.type === config.entrance_types.ENTRANCE.value) || (entrance.type === config.entrance_types.UNPAID.value)));
            this.setState({entrances: entrances});
            let entrance_choices = entrances.map(entrance => ({id: entrance._id, name: entrance.name}));
            this.setState({entrance_choices: entrance_choices});
        } catch(e) {
            console.log(e.message)
        }

    }

    handleOpen = () => {
        this.setState({open: true});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleFileChange = async (e) => {

        if (e) {
            this.setState({
                import_enabled: false
            })
            var reader = new FileReader();
            reader.onload = (e) => {
                let arrayBuffer = reader.result;
                let uInt8Array = new Uint8Array(arrayBuffer);
                this.setState({selected_file_buffer: (uInt8Array.toString()).split(','), import_enabled: true});
            }
            reader.readAsArrayBuffer(e);
            this.setState({selected_file: e})
        } else {
            this.setState({selected_file: null});
            this.setState({selected_file_buffer: null});
        }
    }

    delay = async (ms) => {
        await this.timer(ms);
    }

    timer = (ms) => {
        return new Promise(res => setTimeout(res, ms));
    }

    async addEntrance() {
        try {

            const refresh = this.props.refresh;

            this.handleClose();

            // show swal loading
            const inProgressSwal = Swal.fire({
                title: translate('custom.processing'),
                text: translate('custom.please_wait'),
                allowOutsideClick: false,
                showCancelButton: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            let uid = this.props.record.uid;
            let entrance = this.state.selected_entrance;
            
            let response = await request('/clients/transfer/' + uid + '?channelShort=' + config.channel_types.WEB.short, 'POST', null, {
                'new_uid': uid,
                'entrance_id': entrance
            });

            console.log(response)

            inProgressSwal.close();

            if(response.error) {
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? (convertMessageCodeToMessage(response.error_code)) : (response.error ? response.error : translate('custom.unknown_error')),
                    icon: 'error',
                    confirmButtonText: translate('custom.ok'),
                    confirmButtonColor: config.baseColor
                });
                return;
            }
            
            Swal.fire({
                title: translate('custom.success'),
                text: translate('custom.entrance_added_successfully'),
                icon: 'success',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            });

            refresh();

        } catch(e) {
            Swal.fire({
                title: translate('custom.error'),
                text: e.message ? e.message : translate('custom.unknown_error'),
                icon: 'error',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            });
        }
    }

    render() {

        let {record} = this.props;

        return (
            <div>
                <Button
                    fullWidth
                    variant="contained"
                    style={{
                        backgroundColor: config.successColor,
                        color: 'white'
                    }}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={this.handleOpen}
                >
                    {translate('custom.add_entrance')}
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    // className={classes.modal}
                    style={styles.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        {/* <div className={classes.paper}> */}
                        <div style={styles.paper}>


                            <SimpleForm toolbar={<ClientAddEntranceToolbar />} >

                                <SelectInput
                                    source="entrance"
                                    label={translate('custom.entrance')}
                                    choices={this.state.entrance_choices}
                                    value={this.state.selected_entrance}
                                    onChange={e => this.setState({selected_entrance: e.target.value})}
                                    fullWidth={true}
                                    variant="outlined"
                                />

                                <Button
                                    color="primary"
                                    onClick={this.addEntrance}
                                    variant="contained"
                                    size="small"
                                    fullWidth={true}
                                    disabled={!this.state.import_enabled}
                                >
                                    {translate('custom.add_entrance')}
                                </Button>

                            </SimpleForm>

                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

}

const ClientAddEntrance = (props) => {
    const refresh = useRefresh();
    return <ClientAddEntranceClass {...props} refresh={refresh} />
}
export default ClientAddEntrance;