import React, {Component} from 'react';
import { Chart } from 'react-google-charts';
// import {translate} from "../i18n/customI18nProvider";
import {gFormatCurrency} from "../util";
import theme from "./Theme";
import {translate} from "../i18n/customI18nProvider";

const graph_colors = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.primary.dark
];

const titleTextStyle = {
    color: theme.palette.primary.main,
    fontSize: 24,
    bold: true,
};

class AmountPieChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currencyCode: props.currencyCode,
            currencySymbol: props.currencySymbol
        };
    }

    render() {

        let currency = this.state.currencySymbol ? this.state.currencySymbol : this.state.currencyCode;
        const { statistics } = this.props;

        if(((statistics.global.entrance > 0) + (statistics.global.debit > 0) + (statistics.global.sold > 0)) > 1) {
            
            let view = (
                <div>
                    <Chart
                        chartType="PieChart"
                        // width="100%"
                        height="350px"
                        data={[
                            ['Type', translate('custom.amount')],
                            [translate('custom.entrances'), gFormatCurrency(Math.abs(statistics.global.entrance))],
                            [translate('custom.consumptions'), gFormatCurrency(Math.abs(statistics.global.debit))],
                            [translate('custom.remaining_balances'), gFormatCurrency(Math.abs(statistics.global.sold))]
                        ]}
                        options={{
                            title: '',
                            titleTextStyle,
                            vAxis: {
                                title: translate('custom.amount') + ' (' + currency + ')',
                                format: 'short'
                            },
                            pieHole: 0.4,
                            colors: graph_colors,
                            backgroundColor: {fill: 'transparent'}
                        }}
                        graph_id="DonutChart"
                    />
                </div>
            );
            
            return (
                <div>

                    {view}

                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: 100}}>
                        </div>
                    </div>

                </div>
            )
        } else {
            return (
                <></>
            )
        }

    }
}

export default AmountPieChart;