import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export default createTheme({
    palette: {
        primary: {
            main: '#08607e'
        },
        secondary: {
            main: '#08607e'
        }
    },
    status: {
        danger: 'orange',
    },
});