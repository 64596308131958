import {
    required,
    minValue,
    number,
    maxValue
} from 'react-admin';

export const validateRequired = [required()];
export const validatePositiveNumber = [required(), number(), minValue(0)];
export const validatePositiveNumberWithMax = (max) => [required(), number(), minValue(0), maxValue(max)];
export const validatePositiveNumberNonRequired = [number(), minValue(0)];
export const validateNumber = [required(), number()];
export const validateNumberNonRequired = [number()];
export const validateNumberWithMax = (max) => [required(), number(), maxValue(max)];
