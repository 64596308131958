import React from 'react';
import {
    SimpleForm,
    Toolbar,
    List,
    Datagrid,
    SimpleList,
    TextField,
    Pagination,
    Filter,
    TextInput,
    DateField
} from 'react-admin';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {translate} from "../i18n/customI18nProvider";
import Button  from '@material-ui/core/Button';
import config from "./../config";
// import { request } from "../util";
import Actions from '../components/reactAdmin/Actions';
import GetAppIcon from '@material-ui/icons/GetApp';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ErrorIcon from '@material-ui/icons/Error';
import { request } from '../util';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import { getToken } from '../services/storage';

export const ReportIcon = AssessmentIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="File Name" source="file_name" variant='outlined' alwaysOn />
    </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const DownloadView = ({record}) => {
    if (!record.status || record.status == 'completed') {
        return (
            <GetAppIcon style={{'cursor': 'pointer'}} 
                onClick={() => downloadFile(record)}
            />
        )
    } else if (record.status == 'inprogress') {
        return (
            <HourglassEmptyIcon />
        )
    } else if (record.status == 'errored') {
        return (
            <ErrorIcon />
        )
    } else {
        return (
            <></>
        )
    }

}

function getRequestOptions(method) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    headers['x-access-token'] = getToken();

    let options = {
        method: method,
        headers
    };

    return options;
}

async function downloadFile(record) {
    let response = await fetch(config.apiUrl + '/reports/' + record.file_name, getRequestOptions('GET'));;
            
    if(response.status !== 200) {
        let responseJson = await response.json();
        alert(responseJson.error);
        return;
    }

    try {
        downloadFileFromResponse(response, record.file_name);
    } catch(e) {
        console.log("Error", e);
        alert(translate('custom.download_failed'));
    }
}

async function downloadFileFromResponse(response, file_name, axios=false) {
    let blob;
    if(axios) {
        blob = await response.data;
    } else {
        blob = await response.blob();
    }
    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blob);
}


export class ReportsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            twint_report_xlsx_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.xlsx') + " )",
            twint_report_pdf_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.pdf') + " )",
            card_report_button_message: translate('custom.generate_card_report'),
            automated_report_button_message: translate('custom.download_overall_report'),
            loading: false
        };

        this.isSmall = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
            this.isSmall = true;
        }

    }

    generateTwintReport = async (type='xlsx') => {

        const c = window.confirm(translate('custom.do_you_want_to_generate_twint_report'));
        if(!c)
            return;

        try {
            if(type == 'xlsx') {
                this.setState({
                    loading: true,
                    twint_report_xlsx_button_message: translate('custom.processing')
                });
            } else {
                this.setState({
                    loading: true,
                    twint_report_pdf_button_message: translate('custom.processing')
                });
            }
    
            let response = await fetch(config.apiUrl + '/reports/twint/' + type, getRequestOptions('POST'));;
            
            if(response.status !== 200) {
                let responseJson = await response.json();
                alert(responseJson.error);
                if (type == 'xlsx') {
                    this.setState({
                        loading: false,
                        twint_report_xlsx_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                    });
                } else {
                    this.setState({
                        loading: false,
                        twint_report_pdf_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                    });
                }
                return;
            }

            try {

                await downloadFileFromResponse(response, translate('custom.twint_report') + '.' + type);

                if (type == 'xlsx') {
                    this.setState({
                        loading: false,
                        twint_report_xlsx_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                    });
                } else {
                    this.setState({
                        loading: false,
                        twint_report_pdf_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                    });
                }

                window.location.reload(true);

            } catch(e) {
                console.log("Error", e);
                alert(translate('custom.download_failed'));
                
                if (type == 'xlsx') {
                    this.setState({
                        loading: false,
                        twint_report_xlsx_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                    });
                } else {
                    this.setState({
                        loading: false,
                        twint_report_pdf_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                    });
                }
            }
        } catch(e) {
            alert(translate('custom.unknown_error'));
            if (type == 'xlsx') {
                this.setState({
                    loading: false,
                    twint_report_xlsx_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                });
            } else {
                this.setState({
                    loading: false,
                    twint_report_pdf_button_message: translate('custom.generate_twint_report') + " ( " + translate('custom.' + type) + " )"
                });
            }
        }
        
    };


    generateCardReport = async () => {

        const c = window.confirm(translate('custom.do_you_want_to_generate_card_report'));
        if(!c)
            return;

        try {
            this.setState({
                loading: true,
                card_report_button_message: translate('custom.processing')
            });
    
            let response = await fetch(config.apiUrl + '/reports/card', getRequestOptions('POST'));;
            
            if(response.status !== 200) {
                let responseJson = await response.json();
                alert(responseJson.error);
                this.setState({
                    loading: false,
                    card_report_button_message: translate('custom.generate_card_report')
                });
                return;
            }

            try {

                await downloadFileFromResponse(response, translate('custom.card_report') + '.xlsx');

                this.setState({
                    loading: false,
                    card_report_button_message: translate('custom.generate_card_report')
                });

                window.location.reload(true);

            } catch(e) {
                console.log("Error", e);
                alert(translate('custom.download_failed'));
                this.setState({
                    loading: false,
                    card_report_button_message: translate('custom.generate_card_report')
                });
            }
        } catch(e) {
            alert(translate('custom.unknown_error'));
            this.setState({
                loading: false,
                card_report_button_message: translate('custom.generate_card_report')
            });
        }
        
    };

    generateAutomatedReport = async () => {
        const c = window.confirm(translate('custom.do_you_want_to_download_overall_report'));
        if(!c)
            return;

        try {
            this.setState({
                loading: true,
                automated_report_button_message: translate('custom.processing')
            });

            let response = await axios.get(config.apiUrl + '/reports/automated', {
                responseType: 'blob',
                headers: {
                    'x-access-token': getToken()
                }
            });

            if(response.status !== 200) {
                let responseJson = await response.json();
                window.location.reload();
            } else {
                await downloadFileFromResponse(response, translate('custom.automated_report') + '.zip', true);
            }
            this.setState({
                loading: false,
                automated_report_button_message: translate('custom.download_overall_report')
            });
            return;
        } catch(e) {
            alert(translate('custom.unknown_error'));
            this.setState({
                loading: false,
                automated_report_button_message: translate('custom.download_overall_report')
            });
        }
    }


    render() {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={3} xl={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                this.generateTwintReport('xlsx');
                            }}
                            disabled={this.state.loading}
                        >
                            {this.state.twint_report_xlsx_button_message}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                this.generateTwintReport('pdf');
                            }}
                            disabled={this.state.loading}
                        >
                            {this.state.twint_report_pdf_button_message}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={this.generateCardReport}
                            disabled={this.state.loading}
                        >
                            {this.state.card_report_button_message}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={this.generateAutomatedReport}
                            disabled={this.state.loading}
                        >
                            {this.state.automated_report_button_message}
                        </Button>
                    </Grid>
                </Grid>
                
                <List {...this.props} hasCreate={false} bulkActionButtons={false} actions={<Actions />} filters={<PostFilter />} pagination={<PostPagination />} perPage={50} >
                    {this.isSmall ? (
                        // <SimpleList
                        //     primaryText={record => record.file_name ? record.file_name : record.id }
                        // />
                        <Datagrid>
                            <TextField label="Type" source="type" />
                            <TextField label={translate('custom.file_name')} source="file_name" />
                            <DownloadView />
                        </Datagrid>
                    ) : (
                        <Datagrid>
                            <TextField label="Type" source="type" />
                            <TextField label={translate('custom.file_name')} source="file_name" />
                            {/* <DateField label={translate('custom.creation_date')} source="creation_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} /> */}
                            <DateField label={translate('custom.created_at')} source="created_at" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                            <DateField label={translate('custom.updated_at')} source="updated_at" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                            <DownloadView />
                        </Datagrid>
                    )}
                </List>

            </>
        );
    }

};