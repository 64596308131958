import React, { Component } from 'react';
import '../css/App.css';

import Header from "../components/Header";
import { translate } from "../i18n/customI18nProvider";
export default class Home extends Component {

    render() {
        return (

            <Header
                title={translate('custom.404_not_found')}
                noSide={true}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems:"center",
                    padding: 20
                }}>
                    <h1>{translate('custom.page_not_found')}</h1>
                </div>
            </Header>
        );
    }
}
