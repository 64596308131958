import react from 'react';
import V2Layout from './Layout';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, TextField } from '@mui/material';
import config from '../../config';
import { translate } from '../../i18n/customI18nProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createDevice, getDeviceDetails, saveDeviceDetails } from '../../services/device.service';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import moment from 'moment';

const PHONE_NUMBER_REGEX = /^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

class AddEditDevice extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            device_brands: [],
            mode: null,
            device_id: null,
            title: translate('custom.devices'),
            loading: false,
            is_form_valid: true,
            form_error: {},
            // ------
            updated: '', // date field ( read-only )
            sl_no: '', // string ( read-only )
            status: '', // active, to_avoid, under_repair, loaned, not_available, inactive
            app_version: '', // read-only
            type: '', // smartphone, modem, terminal_pos
            brand: '', // string ( but with dropdown )
            number: '', // string
            operator: '', // string ( but with dropdown )
            holder: '', // string
            date_of_purchase: '', // date field
            date: '', // date field
            balance: '', // number
            subscription: '', // string
        }
    }

    async componentDidMount() {
        // get the mode, if /add, then create a new device
        // if /:device_id, then fetch the device details
        let mode = this.props?.match?.params?.device_id ? 'edit' : 'add';
        let device_id = this.props?.match?.params?.device_id ? this.props.match.params.device_id : null;
        await this.setState({
            loading: true,
            mode,
            device_id,
            title: mode === 'add' ? translate('custom.add_device') : translate('custom.edit_device')
        });

        if (mode === 'edit')
            await this.getDevice();
        
        this.setState({ loading: false });
    }   

    async getDevice() {
        // if device_id, fetch device details
        let device = await getDeviceDetails(this.state.device_id);
        if (device.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: device.error_code ? convertMessageCodeToMessage(device.error_code) : device.error
            });
            return;
        }
        device = device.device;

        await this.setDeviceBrands(device?.type);

        await this.setState({
            updated: device.updatedAt ? moment(device.updatedAt) : null,
            sl_no: device.sl_no ? device.sl_no : '',
            status: device.status ? device.status : '',
            app_version: device.app_version ? device.app_version : '',
            type: device.type ? device.type : '',
            brand: device.brand ? device.brand : '',
            number: device.number ? device.number : '',
            operator: device.operator ? device.operator : '',
            holder: device.holder ? device.holder : '',
            date_of_purchase: device.date_of_purchase ? moment(device.date_of_purchase) : null,
            date: device.date ? moment(device.date) : null,
            balance: device.balance ? device.balance : '',
            subscription: device.subscription ? device.subscription : ''
        });
    }

    async setDeviceBrands(device_type=null) {
        if (device_type) {
            await this.setState({
                device_brands: config.device_brands[device_type]
            });
            return;
        }
        if (this.state.type === '' || this.state.type === null || this.state.type === undefined) {
            await this.setState({ device_brands: [] });
            return;
        }
        await this.setState({
            device_brands: config.device_brands[this.state.type]
        });
    }

    async save() {

        if (!this.isFormValid()) return;

        let payload = {
            status: this.state.status,
            type: this.state.type,
            brand: this.state.brand,
            operator: this.state.operator,
            number: this.state.number,
            holder: this.state.holder,
            date_of_purchase: this.state.date_of_purchase,
            date: this.state.date,
            balance: this.state.balance,
            subscription: this.state.subscription
        }

        let response;
        if (this.state.mode === 'add') {
            response = await createDevice(payload);
        } else if (this.state.mode === 'edit') {
            response = await saveDeviceDetails(this.state.device_id, payload);
        }

        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error
            });
            return;
        }

        this.props.history.push(`/v2/admin/devices`);

        Swal.fire({
            icon: 'success',
            title: translate('custom.success'),
            text: translate('custom.saved_successfully'),
            showConfirmButton: false,
            timer: 1500
        });


    }

    isPhoneNumberValid(type) {

        const phone_number = this.state.number;
        
        if (phone_number === '') {
            return (type === "status") ? true : '';
        }
        
        const m = phone_number.match(PHONE_NUMBER_REGEX);
        if(!m) {
            if(type === "status")
                return false;
            else
                return translate('custom.phone_number_is_not_valid');
        }

        if(type === "status")
            return true;
        else
            return "";

    }

    isFormValid(key=null) {

        let form_error = this.state.form_error;

        if (!key || key == 'status') {
            if (this.state.status === '' || !this.state.status) {
                form_error['status'] = translate('custom.required');
            } else {
                delete form_error['status'];
            }
        }
        
        if (!key || key == 'type') {
            if (this.state.type === '' || !this.state.type) {
                form_error['type'] = translate('custom.required');
            } else {
                delete form_error['type'];
            }
        }

        if (!key || key == 'brand') {
            if (this.state.brand === '' || !this.state.brand) {
                form_error['brand'] = translate('custom.required');
            } else {
                delete form_error['brand'];
            }
        }

        if (!key || key == 'number') {
            if (!this.isPhoneNumberValid('status')) {
                form_error['number'] = this.isPhoneNumberValid('text');
            } else {
                delete form_error['number'];
            }
        }



        if (Object.keys(form_error).length > 0) {
            this.setState({ form_error, is_form_valid: false });
            return false;
        } else {
            this.setState({ form_error: {}, is_form_valid: true });
            return true;
        }

    }

    render() {
        return (
            <V2Layout
                currentMenu='devices'
                title={this.state.title}
            >

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Paper sx={{mt: 2, padding: 2}}>
                    <Grid container spacing={2}>
                        {(this.state.mode == 'edit') ? (
                            <>
                                <Grid item xs={12} md={4} lg={4} xl={4}>
                                    <TextField fullWidth label={translate('custom.sl_no')} variant='outlined' disabled
                                        value={this.state.sl_no}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} xl={4}>
                                    <TextField fullWidth label={translate('custom.app_version')} variant='outlined' disabled
                                        value={this.state.app_version}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} xl={4}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label={translate('custom.updated')}
                                            slotProps={{
                                                textField: { fullWidth: true }
                                            }}
                                            value={this.state.updated ? this.state.updated : null}
                                            disabled
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </>
                        ) : null}
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormControl fullWidth>
                                <InputLabel id='device-status-label'>{translate('custom.status')}</InputLabel>
                                <Select
                                    labelId='device-status-label'
                                    fullWidth
                                    label={translate('custom.status')}
                                    value={this.state.status}
                                    onChange={async (e) => {
                                        await this.setState({ status: e.target.value })
                                        await this.isFormValid('status');
                                    }}
                                    error={this.state.form_error['status'] ? true : false}
                                >
                                    {config.device_statuses.map((status) => {
                                        return <MenuItem value={status.key} key={status.key}>{status.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText error={this.state.form_error['status']}>{this.state.form_error['status']}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormControl fullWidth>
                                <InputLabel id='device-type-label'>{translate('custom.type')}</InputLabel>
                                <Select
                                    labelId='device-type-label'
                                    fullWidth
                                    label={translate('custom.type')}
                                    value={this.state.type}
                                    onChange={async (e) => {
                                        if (e.target.value === '') return this.setState({ type: e.target.value, device_brands: [], brand: '' });
                                        if (this.state.brand != e.target.value) {
                                            await this.setState({
                                                type: e.target.value,
                                                brand: ''
                                            });
                                            await this.setDeviceBrands();
                                        }
                                        await this.isFormValid('type');
                                    }}
                                    error={this.state.form_error['type'] ? true : false}
                                >
                                    {Object.values(config.device_types).map((device_type) => {
                                        return <MenuItem value={device_type.key} key={device_type.key}>{device_type.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText error={this.state.form_error['type']}>{this.state.form_error['type']}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormControl fullWidth>
                                <InputLabel id='device-brand-label'>{translate('custom.brand')}</InputLabel>
                                <Select
                                    labelId='device-brand-label'
                                    fullWidth
                                    label={translate('custom.brand')}
                                    value={this.state.brand}
                                    onChange={async (e) => {
                                        await this.setState({ brand: e.target.value })
                                        await this.isFormValid('brand');
                                    }}
                                    error={this.state.form_error['brand'] ? true : false}
                                >
                                    {this.state.device_brands.map((brand) => {
                                        return <MenuItem value={brand.key} key={brand.key}>{brand.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText error={this.state.form_error['brand']}>{this.state.form_error['brand']}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <FormControl fullWidth>
                                <InputLabel id='device-operator-label'>{translate('custom.operator')}</InputLabel>
                                <Select
                                    labelId='device-operator-label'
                                    fullWidth
                                    label={translate('custom.operator')}
                                    value={this.state.operator}
                                    onChange={(e) => this.setState({ operator: e.target.value })}
                                >
                                    {config.device_operators.map((operator) => {
                                        return <MenuItem value={operator.key} key={operator.key}>{operator.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <TextField fullWidth label={translate('custom.number')} variant='outlined'
                                value={this.state.number}
                                onChange={async (e) => {
                                    await this.setState({ number: e.target.value })
                                    await this.isFormValid('number');
                                }}
                                error={this.state.form_error['number'] ? true : false}
                            />
                            <FormHelperText error={this.state.form_error['number']}>{this.state.form_error['number']}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <TextField fullWidth label={translate('custom.holder')} variant='outlined'
                                value={this.state.holder}
                                onChange={(e) => this.setState({ holder: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={translate('custom.date_of_purchase')}
                                    slotProps={{
                                        textField: { fullWidth: true }
                                    }}
                                    value={this.state.date_of_purchase ? this.state.date_of_purchase : null}
                                    onChange={(e) => this.setState({ date_of_purchase: e })}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={translate('custom.date')}
                                    slotProps={{
                                        textField: { fullWidth: true }
                                    }}
                                    value={this.state.date ? this.state.date : null}
                                    onChange={(e) => this.setState({ date: e })}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <TextField fullWidth label={translate('custom.balance')} variant='outlined' type='number'
                                value={this.state.balance}
                                onChange={(e) => this.setState({ balance: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <TextField fullWidth label={translate('custom.subscription')} variant='outlined'
                                value={this.state.subscription}
                                onChange={(e) => this.setState({ subscription: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 2, justifyContent: 'space-around'}}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Button variant='contained' color='primary' fullWidth
                                onClick={async () => {
                                    await this.save();
                                }}
                            >{this.state.loading ? translate('custom.loading') : translate('custom.save')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </V2Layout>
        );
    }
}

export default AddEditDevice;
