import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

//import green from '@material-ui/core/colors/green';

export default createTheme({
    palette: {
        primary: {
            main: '#08607e'
        },
        secondary: red,
    },
    status: {
        danger: 'orange',
    },
});