import React, {Component} from 'react';

import Button  from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {convertMessageCodeToMessage, downloadFileFromResponse, getRequestOptions, request, requestMultipart} from "../../util";
import {translate} from "../../i18n/customI18nProvider";
import Grid from '@material-ui/core/Grid';
import config from '../../config';

class ExportConsumptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    exportConsumptions = async () => {
        this.setState({loading: true});
        let response = await fetch(config.apiUrl + '/bars/' + this.props.bar_id + '/consumptions/export', getRequestOptions('GET'));
        if(response.status !== 200) {
            let responseJson = await response.json();
            Swal.fire({
                title: translate('custom.error'),
                text: responseJson.error_code ? convertMessageCodeToMessage(responseJson.error_code) : responseJson.error,
                icon: 'error',
                confirmButtonText: translate('custom.ok'),
                confirmButtonColor: config.baseColor
            });
            this.setState({loading: false});
            return;
        } else {
            await downloadFileFromResponse(response, translate('custom.consumptions') + '.xlsx', false);
            this.setState({loading: false});
        }
    }

    render() {
        return (
            <Grid container style={{width: '100%'}} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'right', margin: '10px', borderRadius: '10px'}}>
                    <Button
                        color="primary"
                        onClick={this.exportConsumptions}
                        variant="outlined"
                        size="small"
                        style={{marginLeft: '5px', marginRight: '5px'}}
                        disabled={this.state.loading}
                    >
                        {translate('custom.export')}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default ExportConsumptions;
